import React from "react";
import { Table, InputNumber, Select } from "antd";

const { Option } = Select;

const DummyTable = ({
  data,
  meta,
  onInputChange,
  onSelectChange,
  onDiscountChange,
  onSizeChange,
}) => {
  // console.log(meta);
  // Define columns
  const columns = [
    { title: "Sauda Code", dataIndex: "sauda_code", key: "sauda_code" },

    // { title: "Req. Date", dataIndex: "plan_date", key: "plan_date" },

    { title: "Item Name", dataIndex: "item_desc", key: "item_desc" },

    { title: "Hsn", dataIndex: "hsn", key: "hsn" },

    { title: "Uom", dataIndex: "uom_name", key: "uom_name" },

    {
      title: "Size",
      key: "meta",
      render: (text, record) => (
        <Select
          value={record.size_code || undefined} // Handle null/undefined size_code
          onChange={(value) => onSizeChange(value, record.unique_code)} // Handle size change
          style={{ width: 120 }}
          placeholder="Select Size"
        >
          {(meta || []).map(
            (
              s // Safely handle undefined size array
            ) => (
              <Option key={s.size_code} value={s.size_code}>
                {s.size_nm}
              </Option>
            )
          )}
        </Select>
      ),
    },

    { title: "Grade", dataIndex: "quality_desc", key: "quality_desc" },

    { title: "Quantity", dataIndex: "sauda_qty", key: "sauda_qty" },

    {
      title: "Rate",
      dataIndex: "sauda_rate",
      key: "sauda_rate",
    },

    {
      title: "Dis On",
      key: "dison",
      render: (text, record) => (
        <Select
          value={record.disOn}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "disOn")
          }
          style={{ width: 120 }}
        >
          <Option value="r">Rate</Option>
          <Option value="ta">Total Amt</Option>
        </Select>
      ),
    },

    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter discount"
          value={record.discount}
          onChange={(value) => onDiscountChange(value, record.unique_code)}
          min={0}
        />
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{record.sauda_qty * (record.sauda_rate || 0)}</span>
      ),
    },

    {
      title: "Dis Type",
      key: "distype",
      render: (text, record) => (
        <Select
          value={record.distype}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "distype")
          }
          style={{ width: 120 }}
        >
          <Option value="p">%</Option>
          <Option value="a">A</Option>
        </Select>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "finalRate",
      key: "finalRate",
    },

    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_code} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
