import React from "react";
import { Row, Input, InputNumber } from "antd";
import Table from "@mui/material/Table";
// import Input from "@mui/material/Input";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect, useContext } from "react";

const AdminTable = (props) => {
  // console.log(props, "this is props in admin table");
  // console.log(props, "this is props in admin table");

  const [data, setData] = useState(props.sizeData.custdetail);

  const onInputChange = (value, index, record) => {
    // console.log("Index:", index);
    // console.log("Index:", index);

    // Create a new array with the updated row based on the index
    const updatedRows = data.rows.map((row, i) => {
      if (i === index) {
        return {
          ...row,
          return_qty: value, // Update the return_qty or whichever field you're changing
        };
      } else {
        return row;
      }
    });

    props.setMata((prevData) => {
      // console.log("hi");
      return {
        ...prevData,
        DailyProductionDetail: updatedRows,
      };
    });
    // Update the state with the modified data array
    setData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));

    //Update the state with the new array for props (if needed)
    // props.setMata((prevData) => ({
    //   ...prevData,
    //   DailyProductionDetail: updatedRows,
    // }));

    // console.log("Updated DailyProductionDetail:", updatedRows);
  };

  // const onRemarkChange = (value, index, record) => {
  //   const updatedRows = data.rows.map((row, i) => {
  //     if (i === index) {
  //       return {
  //         ...row,
  //         remarks: value,
  //       };
  //     } else {
  //       return row;
  //     }
  //   });

  //   setData((prevData) => ({
  //     ...prevData,
  //     rows: updatedRows,
  //   }));

  //   props.setMata((prevData) => ({
  //     ...prevData,
  //     ProductionDetail: updatedRows,
  //   }));
  // };

  const columns = [
    { key: "14", title: "Item", dataIndex: "item_name" },
    { key: "16", title: "uom", dataIndex: "uom" },

    { key: "15", title: "Size", dataIndex: "size_name" },

    { key: "17", title: "Grade", dataIndex: "grade" },

    /////////////////////
    { key: "27", title: "Rate", dataIndex: "rate1" },
    { key: "34", title: "Weight", dataIndex: "total_qty" },
    {
      key: "28",
      title: "Return Qty",
      dataIndex: "return_qty",
      editable: true,
      render: (text, record, index) => (
        <InputNumber
          value={text}
          onChange={(e) => {
            onInputChange(e, index, record);
          }}
          style={{ textAlign: "left" }}
        />
      ),
    },

    { key: "24", title: "uniq", dataIndex: "uniq_code" },

    { key: "25", title: "Amount", dataIndex: "amount2" },
  ];

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default AdminTable;
