import { Col, Card, Input, Form, DatePicker, Select, InputNumber } from "antd";
import classes from "../Pages.module.css";
import dayjs from "dayjs";

const { Option } = Select;

const disabledDate = (current) => {
  // Disable days after today (can't select future dates)
  return current && current > dayjs().endOf("day");
};

const DataField = (props) => {
  return (
    <Col lg={props.lg} md={props.md} className={classes["Col"]}>
      <Form layout="vertical">
        <Form.Item
          colon={false}
          style={{ margin: "0", padding: "0" }}
          label={
            <div
              style={{
                padding: "0rem 0.5rem",
                fontSize: "0.6rem",
                fontWeight: "bold",
              }}
              className={classes["Label"]}
            >
              {props.name}
              {props.required ? <span style={{ color: "red" }}> *</span> : ""}
            </div>
          }
        >
          {
            props.type === "Date" ? (
              <DatePicker
                value={props.value ? dayjs(props.value, "DD-MM-YYYY") : null} // Ensure value is either a valid date or null
                format="DD-MM-YYYY"
                style={{
                  textAlign: "left",
                  width: "100%",
                  backgroundColor: "white",
                  color: "#1777C4",
                  fontWeight: "bold",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                allowClear={true}
                bordered={true}
                onChange={(date, dateString) =>
                  props.handleChange(date, dateString, props.param)
                }
                disabledDate={disabledDate}
                onClear={() => props.handleChange(null, null, props.param)} // Handle clearing of the date
              />
            ) : props.type === "Select" ? (
              <Select
                value={props.value === null ? null : props.value}
                showSearch
                onChange={(value) => props.handleChange(value, props.param)}
                bordered={true}
                dropdownStyle={{ textTransform: "capitalize" }}
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  color: "#1777C4",
                  fontWeight: "bold",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.children >= 0}
                filterSort={(optionA, optionB) => optionA.children}
              >
                {props.param !== "cust_code" &&
                  props.param !== "item_code" &&
                  props.param !== "sauda_quality" &&
                  props.options.rows.map((option) => {
                    return (
                      <Option
                        style={{
                          textTransform: "capitalize",
                          color: "#1777C4",
                        }}
                        key={option[props.options.fields[0].name]}
                        value={option[props.options.fields[0].name]}
                      >
                        {option[props.options.fields[1].name]}
                      </Option>
                    );
                  })}

                {props.param === "cust_code" &&
                  props.customers &&
                  props.customers.map((customer) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={customer.distributor_code}
                      value={customer.distributor_code}
                    >
                      {customer.cust_name}
                    </Option>
                  ))}

                {props.param === "item_code" &&
                  props.items &&
                  props.items.map((items) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={items.item_code}
                      value={items.item_code}
                    >
                      {items.item_name}
                    </Option>
                  ))}

                {props.param === "sauda_quality" &&
                  props.cats &&
                  props.cats.map((cats) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={cats.quality_code}
                      value={cats.quality_code}
                    >
                      {cats.quality_name}
                    </Option>
                  ))}
              </Select>
            ) : (
              // props.type === "Input"
              // ?

              <Input
                placeholder="Enter"
                value={props.value ? props.value : null}
                bordered={true}
                onChange={(e) => props.handleChange(e, props.param, props.id)}
                // style={{
                //   width: "100%",
                //   float: "left",
                //   backgroundColor: "white",
                //   color: "000000",
                //   fontWeight: "bold",
                //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                // }}
                onKeyPress={(e) => {
                  if (
                    props.param === "actual_qty" ||
                    props.param === "sauda_rate" ||
                    props.param === "sauda_qty"
                  ) {
                    const regex = /^[0-9.]$/; // Allow only numbers and decimal points

                    // Prevent key press if it's not a number or a period
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }
                }}
                style={{
                  width: "100%",
                  textAlign:
                    props.param === "actual_qty" ||
                    props.param === "sauda_rate" ||
                    props.param === "sauda_qty"
                      ? "right"
                      : "left", // Conditionally set text align
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              />
            )
            // :

            // <Input

            //     placeholder=""

            //     value = {props.value? props.value : null}

            //     bordered = {false}

            //     onChange = {(e) => props.handleChange(e, props.param, props.id)}
            //    // onClick={(e) => props.handleChangebok(e, props.param, props.id)}
            //     style={{ width: "100%" , textAlign: "right", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
            //     />
          }
        </Form.Item>
      </Form>

      {/* // (
      //   <Card
      //     bodyStyle={{ padding: "0.5rem 1rem" }}
      //     bordered={false}
      //     className={classes["Card"]}
      //   >
      //     {props.type === "Select" ? (
      //       <p className={classes["Label"]}>
      //         {props.name}
      //         <span className={classes["Data"]}>
      //           {props.value
      //             ? props.options.rows[
      //                 props.options.rows.findIndex(
      //                   (element) =>
      //                     element[props.options.fields[0].name] === props.value
      //                 )
      //               ][props.options.fields[1].name]
      //             : null}
      //         </span>
      //       </p>
      //     ) : (
      //       <p className={classes["Label"]}>
      //         {props.name}{" "}
      //         <span className={classes["Data"]}>
      //           {props.value ? props.value : null}
      //         </span>
      //       </p>
      //     )}
      //   </Card>
      // )} */}
    </Col>
  );
};

export default DataField;
