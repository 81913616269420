import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Table,
  message,
  Input,
  Card,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import classes from "../Pages.module.css";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const InwardGatePass = () => {
  const history = useNavigate();
  const [data, setData] = useState({
    dealType: null,
    partyName: null,
    orderType: null,
    gatePassType: null,
    gateKeeperName: null,
    vendor: null,
    startDate: null,
    arrivalDate: null,
    invoiceChallanDate: null,
    timeIn: null,
    timeOut: null,
    refGatePassNo: "",
    invoiceChallanNo: "",
    driverName: "",
    truckNo: "",
    gatePassNo: "",
    transporter: null,
  });

  const [dealTypes, setDealTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [gatePassTypes, setGatePassTypes] = useState([]);
  const [gateKeepers, setGateKeepers] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [transporters, setTransporters] = useState([]);
  const [convUoms, setConvUoms] = useState([]);
  const [gatePassNo, setGatePassNo] = useState("");

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/inwarddatagp/gpdatain",
          {
            withCredentials: true,
          }
        );

        // console.log("Data:", response.data);
        // console.log("Data:", response.data);
        setGatePassTypes(response.data.data.gatePassType || []);
        setGateKeepers(response.data.data.gateKeeperName || []);
        setDealTypes(response.data.data.dealType || []);
        setVendors(response.data.data.vender || []);
        setTransporters(response.data.data.transporter || []);
        setOrderTypes(response.data.data.orderType || []);
        setConvUoms(response.data.data.convUom || []);
        setData((prevData) => ({
          ...prevData,
          againstOfOptions: response.data.data.againstOfOptions || [], // Assuming this is the right field
        }));
      } catch (error) {
        message.error("Failed to fetch dropdown options");
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleInputChange = (value, field) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleDropdownChange = (value, param) => {
    setData((prevData) => ({ ...prevData, [param]: value }));
  };

  useEffect(() => {
    const { dealType, vendor, orderType } = data;

    if (dealType && vendor && orderType) {
      axios
        .get("http://sastetab.com/api/v1/inwarddatagp/ingpdat", {
          params: {
            deal_type_cd: dealType,
            party_code: vendor,
            po_type: orderType,
          },
          withCredentials: true,
        })
        .then((response) => {
          const fetchedData = response.data.data.tableData;
          console.log("Received data:", fetchedData);

          if (Array.isArray(fetchedData)) {
            setTableData(fetchedData);
          } else {
            console.error("Expected array but received:", fetchedData);
            message.error("Unexpected data format received from the server");
          }
        })
        .catch((error) => message.error("Failed to fetch table data"));
    }
  }, [data.dealType, data.vendor, data.orderType]);

  const columns = [
    { title: "PO Number", dataIndex: "rmd_code", key: "rmd_code" },
    { title: "Item Name", dataIndex: "item_name", key: "item_name" },
    { title: "Size Name", dataIndex: "size_desc", key: "size_desc" },
    { title: "Grade", dataIndex: "quality_desc", key: "quality_desc" },
    { title: "Conv. Quantity", dataIndex: "convQuantity", key: "convQuantity" },
    { title: "Conv. UOM", dataIndex: "convUOM", key: "convUOM" },
    { title: "Bal. Qty", dataIndex: "total_qty", key: "total_qty" },
    {
      title: (
        <span>
          Quantity<span style={{ color: "red" }}>*</span>
        </span>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          value={record.quantity || ""}
          onChange={(e) =>
            handleQuantityChange(e.target.value, record.unique_code)
          }
        />
      ),
    },
    { title: "HSN", dataIndex: "hsn", key: "hsn" },

    { title: "UNIQUE", dataIndex: "unique_code", key: "unique_code" },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Button onClick={() => handleDeleteRow(record.unique_code)}>
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const handleQuantityChange = (value, uniqueCode) => {
    // Ensure value is a number and not greater than the maximum quantity
    const updatedTableData = tableData.map((row) => {
      if (row.unique_code === uniqueCode) {
        const maxQuantity = row.total_qty;
        const quantity = Math.min(Number(value), maxQuantity);
        return { ...row, quantity };
      }
      return row;
    });

    setTableData(updatedTableData);
  };

  const handleSave = async () => {
    try {
      const requiredFields = [
        "dealType",
        "partyName",
        "orderType",
        "gatePassType",
        "gateKeeperName",
        "vendor",
        "startDate",
        "arrivalDate",
        "invoiceChallanDate",
        "timeIn",
        "timeOut",
        "refGatePassNo",
        "invoiceChallanNo",
        "driverName",
        "truckNo",
        "gatePassNo",
        "transporter",
        "againstOf",
      ];

      // const isFormValid = requiredFields.every(
      //   (field) => data[field] !== null && data[field] !== ""
      // );
      // const isTableValid = tableData.every(
      //   (row) => row.quantity <= row.balQuantity
      // );

      // if (!isFormValid) {
      //   message.error("Please fill in all required fields.");
      //   return;
      // }

      // if (!isTableValid) {
      //   message.error("Quantity cannot exceed the balance quantity.");
      //   return;
      // }

      const payload = {
        ...data,
        tableData: tableData,
      };
      // console.log(payload, "ppppppppppppppppppppppppppppp");

      await axios.post(
        "http://sastetab.com/api/v1/inwarddatagp/saved",
        payload,
        {
          withCredentials: true,
        }
      );

      message.success("Gate pass data saved successfully");
      history("/gate/transaction/inward-view");
    } catch (error) {
      message.error("Failed to save gate pass data");
    }
  };

  const handleDeleteRow = (uniqueCode) => {
    const updatedTableData = tableData.filter(
      (row) => row.unique_code !== uniqueCode
    );
    setTableData(updatedTableData);
  };

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
          margin: "0",
          padding: "10px",
        }}
      >
        Inward Gate Pass
      </p>
      <Form layout="vertical">
        {/* Wrapping entire form in one card */}
        <Card
          // title="Inward Gate Pass Information"
          bordered={true}
          style={{ marginBottom: 8, width: "90%", padding: "10px" }}
        >
          {/* Gate Pass Information */}
          <Row gutter={[8, 8]} className={classes.row}>
            <Col span={8}>
              <Form.Item
                label="Gate Pass Type"
                required
                style={{ marginBottom: 8 }}
              >
                <Select
                  placeholder="Select Gate Pass Type"
                  onChange={(value) =>
                    handleDropdownChange(value, "gatePassType")
                  }
                  value={data.gatePassType}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  {gatePassTypes.map((type) => (
                    <Option key={type.type_code} value={type.type_code}>
                      {type.type_desc}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Against Of"
                required
                style={{ marginBottom: 8 }}
              >
                <Select
                  placeholder="Select Against Of"
                  onChange={(value) => handleDropdownChange(value, "againstOf")}
                  value={data.againstOf}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  <Option key="P" value="P">
                    PO
                  </Option>
                  <Option key="D" value="D">
                    Dispatch
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Truck Number"
                required
                style={{ marginBottom: 8 }}
              >
                <Input
                  value={data.truckNo}
                  onChange={(e) => handleInputChange(e.target.value, "truckNo")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Driver Name"
                required
                style={{ marginBottom: 8 }}
              >
                <Input
                  value={data.driverName}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "driverName")
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Gate Keeper Name"
                required
                style={{ marginBottom: 8 }}
              >
                <Select
                  placeholder="Select Gate Keeper"
                  onChange={(value) =>
                    handleDropdownChange(value, "gateKeeperName")
                  }
                  value={data.gateKeeperName}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  {gateKeepers.map((keeper) => (
                    <Option
                      key={keeper.employee_code}
                      value={keeper.employee_code}
                    >
                      {keeper.employee_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Order and Vendor Details */}

            <Col span={8}>
              <Form.Item label="Deal Type" required style={{ marginBottom: 8 }}>
                <Select
                  placeholder="Select Deal Type"
                  onChange={(value) => handleDropdownChange(value, "dealType")}
                  value={data.dealType}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  {dealTypes.map((type) => (
                    <Option key={type.dealtype_code} value={type.dealtype_code}>
                      {type.dealtype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Vendor" required style={{ marginBottom: 8 }}>
                <Select
                  placeholder="Select Vendor"
                  onChange={(value) => handleDropdownChange(value, "vendor")}
                  value={data.vendor}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  {vendors.map((vendor) => (
                    <Option key={vendor.party_code} value={vendor.party_code}>
                      {vendor.party_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Order Type"
                required
                style={{ marginBottom: 8 }}
              >
                <Select
                  placeholder="Select Order Type"
                  onChange={(value) => handleDropdownChange(value, "orderType")}
                  value={data.orderType}
                  style={{ width: "100%" }}
                  className={classes.select}
                >
                  {orderTypes.map((type) => (
                    <Option key={type.po_type1} value={type.po_type1}>
                      {type.type_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Date Information */}

            <Col span={8}>
              <Form.Item
                label="Start Date"
                required
                style={{ marginBottom: 8 }}
              >
                <DatePicker
                  onChange={(date) => handleInputChange(date, "startDate")}
                  value={data.startDate ? dayjs(data.startDate) : null}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Arrival Date"
                required
                style={{ marginBottom: 8 }}
              >
                <DatePicker
                  onChange={(date) => handleInputChange(date, "arrivalDate")}
                  value={data.arrivalDate ? dayjs(data.arrivalDate) : null}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Invoice/Challan Date"
                required
                style={{ marginBottom: 8 }}
              >
                <DatePicker
                  onChange={(date) =>
                    handleInputChange(date, "invoiceChallanDate")
                  }
                  value={
                    data.invoiceChallanDate
                      ? dayjs(data.invoiceChallanDate)
                      : null
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Table */}
          <Table
            columns={columns}
            dataSource={tableData}
            rowKey="unique_code"
            pagination={{ pageSize: 5 }}
            style={{ marginTop: 16 }}
          />

          {/* Button Row */}
          <Row gutter={[8, 8]} justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button
                type="primary"
                onClick={handleSave}
                icon={<PlusOutlined />}
                className={classes.button}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

InwardGatePass.propTypes = {
  formData: PropTypes.object,
};

export default InwardGatePass;
