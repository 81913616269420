import { Row, Button } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import ReqCodeModal from "./ReqcodeModel"; // Import the modal component
import CustomerModal from "./CustomerItem"; // Import the modal component

const BasicDetails = (props) => {
  // console.log(props);
  const employeeData = useContext(DataContext);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [addres, setAddres] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [modalData, setModalData] = useState([]); // State to store data for the modal
  const [codalData, setCodalData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalForApiVisible, setIsModalForApiVisible] = useState(false); // For API modal
  const [modalDatasize, setModalDataSize] = [];

  const handleReqCodeClick = async () => {
    try {
      // Make the API call when Req Code is clicked
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesOrder/order-with-sauda`,
        { withCredentials: true }
      );

      // console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setModalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleModalRowClick = async (record) => {
    // console.log("Selected Row:", record);

    // Construct the query parameters
    const params = {
      sauda_code: record.sauda_code,
    };

    try {
      // Send the GET request with the query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesOrder/mrir-with-sauda/additional-data/`,
        { params, withCredentials: true }
      );
      // console.log(response);
      // console.log(response);
      // Check if the response contains the expected data
      const requisitionRows = response.data.data.Requisition.rows;

      if (requisitionRows && requisitionRows.length > 0) {
        const custCode = requisitionRows[0].cust_code; // Assuming 'cust_code' is the field in the API
        const dealerCode = requisitionRows[0].dealer_code;
        // Optionally handle the response data here

        // Update the state with the selected value
        props.setData((data) => {
          const newdata = [...data["salesOrder"]];
          newdata[0]["sauda_code"] = record.sauda_code;
          newdata[0]["dealer_name"] = dealerCode;

          // newdata[0]["distributor_code"] = custCode;
          // Call the handleDealerChange function after setting dealer_name
          handleDealerChange(dealerCode, newdata);

          newdata[0]["distributor_code"] = custCode;
          handleAddressChange(custCode, newdata);

          return {
            ...data,
            salesOrder: newdata,
            bookingSize: response.data.data.Requisition.rows,
            size: response.data.data.size.rows,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching additional data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalClose = () => {
    setIsModalForApiVisible(false); // Close the API modal
  };

  /////////////////////////for add more item
  // const handleButtonClick = () => {
  //   // Add your button click logic here
  //   console.log("Button clicked", props.data);
  // };

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      const { dealer_name, distributor_code } = props.data;

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesOrder/additional-data-of-customer-other-sauda?dealercode=${dealer_name}&customer_code=${distributor_code}`,
        { withCredentials: true }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setCodalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalForApiVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalRowClick = async (record) => {
    console.log(record);
    props.setData((data) => {
      return {
        ...data,

        bookingSize: [...data.bookingSize, record],
      };
    });
  };

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["salesOrder"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  const handleSChange = async (val, param) => {
    if (param === "type") {
      // Handle type change
      props.setData((data) => {
        const newdata = [...data["salesOrder"]];
        newdata[0][param] = val;

        // If the new type is "w", set req_code to null
        if (val === "w" && data.salesOrder[0].sauda_code) {
          newdata[0]["sauda_code"] = null;
        }

        return {
          ...data,
          salesOrder: newdata,
          bookingSize: [
            {
              item_code: null,
              size_code: null,
              quality: null,
              uom: null,
              no_pcs: null,
              qty: null,
              book_rate_guage: null,
              discount_on: null,
              discount_amount: null,
              bk_rate: null,
              booking_rate: null,
              dis_type: null,
              tot_item_amt: null,
              net_rate: null,
              net_size_rate: null,
            },
          ],
        };
      });
    }

    props.setData((data) => {
      // Create a new copy of salesOrder to avoid mutating the state directly
      const newdata = [...data["salesOrder"]];

      // if (param === "gate_pass") {
      //   // Nullify purreqDetail when req_code changes
      //   props.setData((data) => {
      //     const newdata = [...data.mrirHdr];
      //     newdata[0][param] = val;

      //     return {
      //       ...data,
      //       mrirHdr: newdata,
      //       mrirDetail: null, // Set purreqDetail to null
      //     };
      //   });
      //   try {
      //     props.onReqCodeChange(val);
      //     // API call using axios
      //     const response =  axios.get(
      //       `http://sastetab.com/api/v1/purchaseorder/purchase_order_with_requisition/${val}`,
      //       { withCredentials: true }
      //     );

      //     // console.log("API Response:", response.data.data.customer.rows);
      //     setIsModalVisible(true);
      //     setModalData(response.data.data.customer.rows);

      //     // Update the state with the API response
      //     props.setData((data) => {
      //       const newdata = [...data["poHdr"]];
      //       newdata[0][param] = val;

      //       return {
      //         ...data,
      //         poHdr: newdata,
      //         purreqDetail: response.data.data.customer.rows, // Store the API response here
      //       };
      //     });
      //   } catch (error) {
      //     console.error("Error fetching data:", error);
      //     // Handle the error as needed (e.g., set an error state or show a message)
      //   }
      // }

      if (param === "dealer_name") {
        setSelectedDealer(val);
        // console.log(data, "data when dealer selected");

        // Clear previous values when a new dealer is selected
        newdata[0].distributor_code = null; // Reset distributor code
        setCustomers(null); // Reset customers to null

        // Make API call to fetch customer list based on dealer code
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-customer/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const customer = response.data.data.customer.rows; // Replace with the actual response structure
            setCustomers(customer);
          })
          .catch((error) => {
            console.error("Error fetching customer list:", error);
          });
      }

      if (param === "distributor_code") {
        // Reset delivery site code when a new distributor code is selected
        newdata[0].del_site_code = null; // Reset delivery site code
        setAddres(null); // Reset address or other related fields

        // console.log(props);

        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-cust/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const add = response.data.data.custdetail.rows; // Replace with the actual response structure
            setAddres(add); // Update state with the new address or details
          })
          .catch((error) => {
            console.error("Error fetching customer detail:", error);
          });
      }

      // Update the salesOrder object with the new parameter value
      newdata[0][param] = val;

      // Return the updated state
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["salesOrder"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  const handleDealerChange = (dealerCode, newdata) => {
    setSelectedDealer(dealerCode);
    // console.log(newdata, "data when dealer selected");

    // Clear previous values when a new dealer is selected
    newdata[0].distributor_code = null; // Reset distributor code
    setCustomers(null); // Reset customers to null

    // Make API call to fetch customer list based on dealer code
    axios
      .get(
        employeeData.URL +
          "/api/v1/salesInvoice/additional-data-of-customer/" +
          dealerCode,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const customer = response.data.data.customer.rows; // Replace with the actual response structure
        setCustomers(customer);
      })
      .catch((error) => {
        console.error("Error fetching customer list:", error);
      });
  };

  const handleAddressChange = (dealerCode, newdata) => {
    setSelectedDealer(dealerCode);
    // console.log(newdata, "data when dealer selected");

    // Clear previous values when a new dealer is selected
    newdata[0].del_site_code = null; // Reset distributor code
    setAddres(null); // Reset customers to null

    // Make API call to fetch customer list based on dealer code
    axios
      .get(
        employeeData.URL +
          "/api/v1/salesInvoice/additional-data-of-cust/" +
          dealerCode,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const customer = response.data.data.custdetail.rows; // Replace with the actual response structure
        setAddres(customer);
      })
      .catch((error) => {
        console.error("Error fetching customer list:", error);
      });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="order_code"
            value={props.data.order_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          options={props.ad.TYPE}
          handleChange={handleSChange}
          type="Select"
          id="type"
          required="True"
          name="Order Type"
          param="type"
          value={props.data.type}
          rules={[{ message: "missing name" }]}
          disabled={props.editMode1}
        />

        {props.data.type !== "w" && (
          <DataField
            editMode={props.editMode}
            lg={8}
            md={24}
            options={props.ad.TYPE}
            onClick={handleReqCodeClick} // Add onClick handler here as well
            id="type"
            required="True"
            name="Contract Code"
            param="sauda_code"
            value={props.data.sauda_code}
            rules={[{ message: "missing name" }]}
            disabled={props.editMode1}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleDChange}
          name="Order DATE"
          type="Date"
          required="True"
          param="booking_date"
          value={props.data.booking_date}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEALER_NAME}
          type="Select"
          required="True"
          name="DEALER NAME"
          param="dealer_name"
          value={props.data.dealer_name}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DISTRIBUTOR_CODE}
          type="Select"
          name="CUSTOMER NAME"
          required="True"
          param="distributor_code"
          customers={props.editMode1 ? props.customers : customers}
          value={props.data.distributor_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ORDER_TYPE} type = "Select" name = "ORDER TYPE" param = "order_type" value = {props.data.order_type}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.INVOICE_TYPE_CODE} type = "Select" name = "INVOICE TYPE" param = "invoice_type_code" value = {props.data.invoice_type_code}/>  */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT DAYS" param = "payment_days" value = {props.data.payment_days}/>    */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEL_SITE_CODE}
          type="Select"
          name="DELIVERY SITE"
          required="True"
          param="del_site_code"
          addres={props.editMode1 ? props.addres : addres}
          value={props.data.del_site_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.AUTH_STATUS} type = "Select" name = "AUTH STATUS" param = "auth_status" value = {props.data.auth_status}/>   */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.FREIGHT_TYPE_CODE}
          type="Select"
          name="FREIGHT TYPE"
          required="True"
          param="freight_type_code"
          value={props.data.freight_type_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} name = "CUSTOMER PO DATE" type="Date" param = "customer_po_date" value = {props.data.customer_po_date}/>   */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="CUSTOMER PO NUMBER"
          type="str"
          param="customer_po_no"
          required="True"
          value={props.data.customer_po_no}
        />
        {/* <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.BROKER_CODE}
          type="Select"
          name="BROKER NAME"
          param="broker_code"
          value={props.data.broker_code}
        /> */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.PAYMENT_CODE} type = "Select" name = "PAYMENT" param = "payment_code" value = {props.data.payment_code}/>  */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TOLERANCE"  param = "tolerance" value = {props.data.tolerance}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT AMOUNT"  param = "payment_amt" value = {props.data.payment_amt}/>   */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="REMARKS"
          type="str"
          param="remarks"
          value={props.data.remarks}
        />
        {props.data.type !== "w" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: 0 }}
          >
            Add item
          </Button>
        )}
      </Row>

      <ReqCodeModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRowClick={handleModalRowClick} // Function to handle when a row in the modal is clicked
        data={modalData} // Pass the data to be displayed in the modal
        // size={modalDatasize}
      />

      <CustomerModal
        title="API Data Modal"
        visible={isModalForApiVisible}
        onRowClick={handleApiModalRowClick}
        onClose={() => setIsModalForApiVisible(false)}
        // onCancel={handleApiModalClose}
        data1={codalData}
      />
    </div>
  );
};

export default BasicDetails;
