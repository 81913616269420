import React, { useState, useRef, useImperativeHandle } from "react";
import { Col, Card, Input, Form, DatePicker, Select } from "antd";
import classes from "../Pages.module.css";
import dayjs from "dayjs";

const { Option } = Select;

const DataField = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  // Expose focus method to parent component
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  // Handle key down events
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (props.type === "Date") {
        if (isOpen) {
          const picker = inputRef.current.picker;
          if (picker) {
            const selectedDate = picker.state.value;
            if (selectedDate) {
              props.handleChange(
                selectedDate,
                selectedDate.format("DD-MM-YYYY"),
                props.param
              );
              setIsOpen(false);
              if (props.onNextFocus) {
                props.onNextFocus();
              }
            }
          }
        } else {
          setIsOpen(true);
        }
      } else if (props.type === "Select") {
        if (!isOpen) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
          if (props.onNextFocus) {
            props.onNextFocus();
          }
        }
      } else {
        if (props.onNextFocus) {
          props.onNextFocus();
        }
      }
    }
  };

  // Handle date change
  const handleDateChange = (date, dateString) => {
    props.handleChange(date, dateString, props.param);
    setIsOpen(false);
    if (props.onNextFocus) {
      props.onNextFocus();
    }
  };
  // const disabledDate = (current) => {
  //   // Can't select days before today and today
  //   return current && current < dayjs().startOf("day");
  // };

  const disabledDate = (current) => {
    // Disable days after today (can't select future dates)
    return current && current > dayjs().endOf("day");
  };

  return (
    <Col lg={props.lg} md={props.md} className={classes["Col"]}>
      {props.editMode ? (
        <Form layout="vertical">
          <Form.Item
            colon={false}
            style={{ margin: "0", padding: "0" }}
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                }}
                className={classes["Label"]}
              >
                {props.name}
                {props.required ? <span style={{ color: "red" }}> *</span> : ""}
              </div>
            }
          >
            {props.type === "Date" ? (
              <DatePicker
                value={props.value ? dayjs(props.value, "DD-MM-YYYY") : null} // Ensure value is either a valid date or null
                format="DD-MM-YYYY"
                style={{
                  textAlign: "left",
                  width: "100%",
                  backgroundColor: "white",
                  color: "#1777C4",
                  fontWeight: "bold",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                onChange={handleDateChange}
                ref={inputRef}
                onKeyDown={handleKeyDown}
                open={isOpen}
                onOpenChange={(status) => setIsOpen(status)}
                disabledDate={disabledDate} // Disable dates before today
                showToday={false} // Hide "Today" button in the picker
              />
            ) : props.type === "Select" ? (
              <Select
                value={props.value === null ? null : props.value}
                showSearch
                onChange={(value) => props.handleChange(value, props.param)}
                bordered={true}
                dropdownStyle={{ textTransform: "capitalize" }}
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  color: props.disabled ? "black" : "#1777C4", // Conditionally set text color
                  fontWeight: props.disabled ? "bold" : "bold", // Maintain bold but can change if needed
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                disabled={props.disabled}
                getPopupContainer={(triggerNode) => triggerNode.parentNode} // Required for styling
                // style={{
                //   width: "100%",
                //   textAlign: "left",
                //   backgroundColor: "white",
                //   textTransform: "capitalize",
                //   color: "#1777C4",
                //   fontWeight: "bold",
                // }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.children >= 0}
                filterSort={(optionA, optionB) => optionA.children}
              >
                {props.param !== "distributor_code" &&
                  props.param !== "del_site_code" &&
                  props.options.rows.map((option) => {
                    return (
                      <Option
                        style={{
                          textTransform: "capitalize",
                          color: "#1777C4",
                        }}
                        key={option[props.options.fields[0].name]}
                        value={option[props.options.fields[0].name]}
                      >
                        {option[props.options.fields[1].name]}
                      </Option>
                    );
                  })}
                {props.param === "distributor_code" &&
                  props.customers &&
                  props.customers.map((customer) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={customer.distributor_code}
                      value={customer.distributor_code}
                    >
                      {customer.cust_name}
                    </Option>
                  ))}

                {props.param === "del_site_code" &&
                  props.addres &&
                  props.addres.map((addres) => (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={addres.del_site_code}
                      value={addres.del_site_code}
                    >
                      {addres.add_1}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input
                placeholder="Enter"
                id={props.id}
                value={props.value ? props.value : ""}
                ref={inputRef}
                onKeyDown={handleKeyDown}
                onChange={(e) => props.handleChange(e, props.param, props.id)}
                onClick={props.onClick}
                disabled={props.disabled}
                border={true}
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  // color: props.disabled ? "black" : "#1777C4", // Conditionally set text color
                  // fontWeight: props.disabled ? "bold" : "bold", // Maintain bold but can change if needed
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              />
            )}
          </Form.Item>
        </Form>
      ) : (
        <Card
          bodyStyle={{ padding: "0.5rem 1rem" }}
          bordered={false}
          className={classes["Card"]}
        >
          {props.type === "Select" ? (
            <p className={classes["Label"]}>
              {props.name}
              <span className={classes["Data"]}>
                {props.value
                  ? props.options.rows[
                      props.options.rows.findIndex(
                        (element) =>
                          element[props.options.fields[0].name] === props.value
                      )
                    ][props.options.fields[1].name]
                  : null}
              </span>
            </p>
          ) : (
            <p className={classes["Label"]}>
              {props.name}{" "}
              <span className={classes["Data"]}>
                {props.value ? props.value : ""}
              </span>
            </p>
          )}
        </Card>
      )}
    </Col>
  );
});

export default DataField;
