import React from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Chat from "./Chat";
import AdminChat from "./AdminChat";
import { Row, Col } from "antd";
import { FaRobot } from "react-icons/fa"; // Import the robot icon
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import classes from "./PayrollPageExtra.module.css";
// import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";

import SiderContentS from "../../components/PurchaseModule/SiderContentS/SiderContentS";
// import Sidebar from "./Sidebar/Sidebar";
// import Main from "./Main/Main";
import { useEffect, useState } from "react";
import FieldDataS from "../../components/PurchaseModule/FieldDataS/FieldDataS";

import { DataProvider } from "../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;

const StockModule = (props) => {
  // console.log(props, "props in purchase");

  const [profiledata, setProfiledata] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [miscList, setMiscList] = useState(null);
  const [userList, setUserList] = useState(null);
  const location = useLocation();
  // Extract state if it exists

  // // console.log(userData.data.user, "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const employeeData = {
    URL: props.url,
  };

  useEffect(() => {
    axios
      .get(props.url + "/api/v1/purchaseorder/chat-bot-icon", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setUserList((miscList) => {
          const newList = response.data.data.site;
          // console.log(newList.spec_code, "ssssssssssssssssss");
          return newList;
        });
      })

      .catch((err) => {
        // console.log(err.response);
      });
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "transparent" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: "transparent" }}
      >
        <SiderContentS url={props.url} miscList={miscList} />
      </Sider>

      <Layout className={classes["SiteLayout"]}>
        <Scrollbars>
          <DataProvider value={employeeData}>
            <Content>
              <FieldDataS />
            </Content>
          </DataProvider>
        </Scrollbars>
        <Row
          gutter={16}
          style={{
            backgroundColor: "transparent",
            width: "4rem",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            textAlign: "right",
          }}
        >
          <Col md={24}>
            {userList && userList.spec_code === "admin" ? (
              <AdminChat />
            ) : (
              <Chat />
            )}
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default StockModule;
