import React, { useRef, useState, useEffect } from "react";
import { Row, Modal, Table, Button, Input, Tooltip, message } from "antd";
import PropTypes from "prop-types";
import axios from "axios";
import classes from "../Pages.module.css";
import { AiFillDelete } from "react-icons/ai";
import DataField from "./DatafieldPR";
import { v4 as uuidv4 } from "uuid";

//import React, {   } from 'react';
//import {    } from 'antd';

const BasicDetailPR = (props) => {
  // console.log(props);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const someOtherRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const newCols = props.ad.ACCOUNT_CODE.fields.map((col) => ({
      title: col.name.split("_").join(" ").toUpperCase(),
      dataIndex: col.name,
      key: col.name,
    }));

    const newNewCols = [...newCols];
    setColumns(newNewCols);

    const newRows = props.ad.ACCOUNT_CODE.rows.map((row, index) => ({
      key: uuidv4(), // Assign unique key
      SNO: index + 1,
      ...row,
    }));
    setRows(newRows);
  }, [props.ad.ACCOUNT_CODE]);

  // console.log(props.ad, "afunxywetyhjwxvkh8ketxjk,,qg.ocag");

  const planDateRef = useRef(null);
  const requirementDateRef = useRef(null);
  const departmentRef = useRef(null);
  const requestedByRef = useRef(null);
  const requestedByRefrate = useRef(null);

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    let mc = null;
    if (param === "mrir_code") {
      axios
        .get(
          `http://sastetab.com/api/v1/purchaseReturnRoute/additional-data-of-item`,
          {
            withcredentials: true,
          }
        )
        .then((response) => {
          props.setmrirdata(response.data.data);
        });
      // props.setmrirdata(response.data.data);
    }
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0][param] = val;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const handleRowClick = async (record) => {
    // console.log("Clicked Row Data:", record);
    const val = record.mrir_code;

    const response = await axios.get(
      `http://sastetab.com/api/v1/purchaseReturnRoute/table-data/${val}`,
      {
        withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
      }
    );

    const responseData = response.data.ACCOUNT_CODE.rows.map((item) => ({
      ...item,
      key: uuidv4(), // Assign unique key
    }));
    // console.log("Response Data:", responseData);
    setTableData(responseData); // Set tableData with responseData

    // const response = await axios.get(`/api/endpoint`, {
    //   params: {
    //     mrir_code: record.mrir_code
    //   }
    // });

    props.setData((data) => ({
      ...data,
      poHdr: [
        {
          ...data.poHdr[0],
          mrir_code: record.mrir_code,
          truck_no: record.truck_no,
          remarks: record.remarks,
          party_code: record.party_code,
          bill_no: record.bill_no,
          mrir_date: record.mrir_date,
          bill_date: record.bill_date,
          // preturn_date: record.preturn_date,
          deal_type_cd: record.deal_code,
          preturn_code: record.preturn_code,
        },
      ],
      purreqDetail: responseData,
    }));

    setIsModalVisible(false); // Close the modal after selection
  };

  const handleReturnPcsChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].return_pcs = value;
    setTableData(updatedTableData);
  };

  const handleReturnWeightChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].return_wt = value;
    // updatedTableData[index].return_pc = value;
    // updatedTableData[index].remark = value;
    updatedTableData[index].amount = value * updatedTableData[index].rate; // Recalculate amount
    setTableData(updatedTableData);
  };

  const handleRemarkChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].remark = value;
    setTableData(updatedTableData);
  };

  const handleDeleteRow = (key) => {
    const updatedTableData = tableData.filter((item) => item.key !== key);
    setTableData(updatedTableData);
  };

  // const validateFields = () => {
  //   const requiredFields = [
  //     'mrir_code', 'truck_no', 'remarks', 'party_code',
  //     'mrir_date', 'bill_date', 'preturn_date', 'deal_type_cd', 'preturn_code'
  //   ];
  //   const newErrors = {};
  //   requiredFields.forEach(field => {
  //     if (!props.data[field]) {
  //       newErrors[field] = 'This field is required';
  //     }
  //   });
  //   return newErrors;
  // };

  const handleSave = async () => {
    try {
      const response = await axios.post(
        "http://sastetab.com/api/v1/savePurchaseReturn",
        {
          tableData,
        },
        {
          withCredentials: true,
        }
      );
      message.success("Data saved successfully!");
    } catch (error) {
      message.error("Failed to save data!");
      console.error("Error saving data:", error);
    }
  };

  const itemColumns = [
    { title: "Item Name", dataIndex: "item_name", key: "item_name" },
    { title: "Size Name", dataIndex: "size_name", key: "size_name" },
    { title: "Quality Name", dataIndex: "grade", key: "quality_name" },
    {
      title: "Return Pcs",
      dataIndex: "return_pcs",
      key: "return_pcs",
      render: (text, record, index) => (
        <Input
          value={record.return_pcs}
          onChange={(e) => handleReturnPcsChange(index, e.target.value)}
        />
      ),
    },
    {
      title: "Return Wt",
      dataIndex: "return_wt",
      key: "return_wt",
      render: (text, record, index) => (
        <Input
          value={record.return_wt}
          onChange={(e) => handleReturnWeightChange(index, e.target.value)}
        />
      ),
    },
    { title: "Mrir Pcs", dataIndex: "no_pcs", key: "mrir_pcs" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (text, record, index) => (
        <Input
          value={record.remark}
          onChange={(e) => handleRemarkChange(index, e.target.value)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => handleDeleteRow(record.key)}>
          <AiFillDelete />
        </Button>
      ),
    },
  ];

  // console.log(props.data);

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={15}
            md={24}
            name="Requisition Code"
            param="REQ_CODE"
            value={props.data.req_code}
          />
        )}
        {/* <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.mrir_code}
          handleChange={handleSChange}
          type="Select"
          id="MRIR_Code"
          required="True"
          name="MRIR_no"
          param="MRIR_Code"
          value={props.data.mrir_code}
          rules={[{ message: "missing code" }]}
          ref={requirementDateRef}
          onNextFocus={() => departmentRef.current.focus()}
        />   */}

        {
          <DataField
            editMode={props.editMode}
            lg={5}
            md={24}
            handleChange={handleDChange}
            type="Date"
            name="Return Date"
            id="preturn_Date"
            required="True"
            param="preturn_Date"
            value={props.data.preturn_date}
            ref={departmentRef}
            onNextFocus={() => requestedByRefrate.current.focus()}
          />
        }

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          name="MRIR_code"
          type="Input"
          param="mrir_code"
          value={props.data.mrir_code}
          onClick={handleOpenModal}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          name="Vendor"
          required="True"
          id="party_code"
          param="party_code"
          value={props.data.party_code}
          ref={requestedByRef}
          onNextFocus={null}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleDChange}
          type="Date"
          name="MRIR Date"
          required="True"
          id="mrir_Date"
          param="mrir_Date"
          value={props.data.mrir_date}
          ref={requestedByRef}
          onNextFocus={null}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          id="bill_date"
          required="True"
          name="Bill Date"
          param="bill_date"
          value={props.data.bill_date}
          rules={[{ message: "missing name" }]}
          ref={planDateRef}
          onNextFocus={() => requirementDateRef.current.focus()}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          name="Bill No"
          required="True"
          id="Bill_no"
          param="Bill_no"
          value={props.data.bill_no}
          ref={requestedByRef}
          onNextFocus={null}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          type="input"
          name="Truck No"
          required="True"
          param="Truck_No"
          value={props.data.truck_no}
          ref={departmentRef}
          onNextFocus={() => requestedByRefrate.current.focus()}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          type="Input"
          name="Remarks"
          required="True"
          id="remarks"
          param="remarks"
          value={props.data.remarks}
          ref={requestedByRef}
          onNextFocus={null}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          id="deal_type_cd"
          required="True"
          name="Deal Type"
          param="deal_type_cd"
          value={props.data.deal_type_cd}
          rules={[{ message: "missing name" }]}
          ref={requirementDateRef}
          onNextFocus={() => departmentRef.current.focus()}
        />
      </Row>
      <Modal
        title="SELECT ANY CODE HERE"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        width={1000}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={rows}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
          })}
          rowKey={(record) => record.id}
        />
      </Modal>
      <Table
        columns={itemColumns}
        dataSource={tableData}
        rowKey={(record) => record.key} // Use a unique identifier for the row key
      />
    </div>
  );
};

export default BasicDetailPR;
