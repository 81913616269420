import { Row, Col, Button } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import ReqCodeModal from "./ReqcodeModel"; // Import the modal component
import CustomerModal from "./CustomerItem"; // Import the modal component

const BasicDetails = (props) => {
  // console.log(props);
  const employeeData = useContext(DataContext);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [addres, setAddres] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [modalData, setModalData] = useState([]); // State to store data for the modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [codalData, setCodalData] = useState([]);
  const [isModalForApiVisible, setIsModalForApiVisible] = useState(false);
  const handleReqCodeClick = async () => {
    try {
      // Make the API call when Req Code is clicked
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesInvoice/invoie-hdr-data-when-invoice-type-with-order`,
        { withCredentials: true }
      );

      // console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setModalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleModalRowClick = async (record) => {
    // console.log("Selected Row:", record);

    // Construct the query parameters
    const params = {
      booking_code: record.booking_code,
    };

    try {
      // Send the GET request with the query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesInvoice/invoie-det-data-when-invoice-type-with-order/`,
        { params, withCredentials: true }
      );

      console.log("API Response:", response.data.data.Requisition.rows);
      // Check if the response contains the expected data
      // const requisitionRows = response.data.data.Requisition.rows;

      const custCode = record.distributor_code; // Assuming 'cust_code' is the field in the API
      const dealerCode = record.dealer_name;
      const add = record.del_side_code;
      // Optionally handle the response data here

      // Update the state with the selected value
      props.setData((data) => {
        const newdata = [...data["salesInvoice"]];
        newdata[0]["booking_no"] = record.booking_code;
        newdata[0]["dealer_code"] = dealerCode;

        // newdata[0]["distributor_code"] = custCode;
        // Call the handleDealerChange function after setting dealer_name
        handleDealerChange(dealerCode, newdata);

        newdata[0]["distributor_code"] = custCode;
        newdata[0]["del_add"] = add;
        handleAddressChange(custCode, newdata);

        return {
          ...data,
          salesInvoice: newdata,
          invoiceSize: response.data.data.Requisition.rows,
        };
      });
    } catch (error) {
      console.error("Error fetching additional data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      const { dealer_code, distributor_code, booking_no } = props.data;

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/salesInvoice/additional-data-of-customer-other-sauda?dealercode=${dealer_code}&customer_code=${distributor_code}&bookingNo=${booking_no}`,
        { withCredentials: true }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setCodalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalForApiVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalRowClick = async (record) => {
    console.log(record);
    props.setData((data) => {
      return {
        ...data,

        invoiceSize: [...data.invoiceSize, record],
      };
    });
  };

  const handleChangen = (value, param) => {
    props.setData((data) => {
      const newdata = [...data["salesInvoice"]];
      newdata[0][param] = value; // Use value directly instead of e.target.value
      return {
        ...data,
        salesInvoice: newdata,
      };
    });
  };

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["salesInvoice"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        salesInvoice: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    if (param === "invoice_type") {
      // Handle type change
      props.setData((data) => {
        const newdata = [...data["salesInvoice"]];
        newdata[0][param] = val;

        // If the new type is "w", set req_code to null
        if (val === "w" && data.salesInvoice[0].booking_no) {
          newdata[0]["booking_no"] = null;
        }

        return {
          ...data,
          salesInvoice: newdata,
          invoiceSize: [
            {
              item_code: null,
              hsn: null,
              uom_for_items: null,
              size_code: null,
              quality: null,
              no_pcs: null,
              qty: null,
              booking_rate: null,
              discount_on: null,
              discount_amt: null,
              dis_type: null,
              bk_rate: null,
              itemqtyamount: null,
            },
          ],
        };
      });
    }

    props.setData((data) => {
      // Create a new copy of salesOrder to avoid mutating the state directly
      const newdata = [...data["salesInvoice"]];

      if (param === "dealer_code") {
        setSelectedDealer(val);
        // console.log(data, "data when dealer selected");

        // Clear previous values when a new dealer is selected
        newdata[0].distributor_code = null; // Reset distributor code
        setCustomers(null); // Reset customers to null

        // Make API call to fetch customer list based on dealer code
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-customer/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const customer = response.data.data.customer.rows; // Replace with the actual response structure
            setCustomers(customer);
          })
          .catch((error) => {
            console.error("Error fetching customer list:", error);
          });
      }

      if (param === "distributor_code") {
        // Reset delivery site code when a new distributor code is selected
        newdata[0].del_site_code = null; // Reset delivery site code
        setAddres(null); // Reset address or other related fields

        // console.log(props);

        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-cust/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const add = response.data.data.custdetail.rows; // Replace with the actual response structure
            setAddres(add); // Update state with the new address or details
          })
          .catch((error) => {
            console.error("Error fetching customer detail:", error);
          });
      }

      // Update the salesOrder object with the new parameter value
      newdata[0][param] = val;

      // Return the updated state
      return {
        ...data,
        salesInvoice: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["salesInvoice"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        salesInvoice: newdata,
      };
    });
  };

  const handleDealerChange = (dealerCode, newdata) => {
    setSelectedDealer(dealerCode);
    // console.log(newdata, "data when dealer selected");

    // Clear previous values when a new dealer is selected
    newdata[0].distributor_code = null; // Reset distributor code
    setCustomers(null); // Reset customers to null

    // Make API call to fetch customer list based on dealer code
    axios
      .get(
        employeeData.URL +
          "/api/v1/salesInvoice/additional-data-of-customer/" +
          dealerCode,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const customer = response.data.data.customer.rows; // Replace with the actual response structure
        setCustomers(customer);
      })
      .catch((error) => {
        console.error("Error fetching customer list:", error);
      });
  };

  const handleAddressChange = (dealerCode, newdata) => {
    setSelectedDealer(dealerCode);
    // console.log(newdata, "data when dealer selected");

    // Clear previous values when a new dealer is selected
    newdata[0].del_site_code = null; // Reset distributor code
    setAddres(null); // Reset customers to null

    // Make API call to fetch customer list based on dealer code
    axios
      .get(
        employeeData.URL +
          "/api/v1/salesInvoice/additional-data-of-cust/" +
          dealerCode,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const customer = response.data.data.custdetail.rows; // Replace with the actual response structure
        setAddres(customer);
      })
      .catch((error) => {
        console.error("Error fetching customer list:", error);
      });
  };

  const handleChangegross = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["salesInvoice"]];
      // console.log(data);
      newdata[0][param] = e.target.value;
      props.data.net_wt =
        data.salesInvoice[0].gross_wt - data.salesInvoice[0].tare_wt;

      //    props.data['salesInvoice'][0]['net_wt'] = data['salesInvoice'][0]['gross_wt'] - data['salesInvoice'][0]['tare_wt']

      // console.log(newdata);
      return {
        ...data,
        salesInvoice: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {/* {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="order_code"
            value={props.data.order_code}
          />
        )} */}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.INVOICE_TYPE}
          id="invoice_type"
          type="Select"
          param="invoice_type"
          name="INVOICE TYPE"
          required="True"
          value={props.data.invoice_type}
          disabled={props.editMode1}
        />

        {props.data.invoice_type !== "w" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            onClick={handleReqCodeClick}
            id="booking_no"
            name="BOOKING NO"
            param="booking_no"
            value={props.data.booking_no}
            disabled={props.editMode1}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEALTYPE_CODE}
          type="Select"
          id="deal_type"
          name="DEAL TYPE"
          param="dealtype_code"
          required="True"
          value={props.data.dealtype_code}
          style={{
            borderColor: props.data.dealtype_code == null ? "red" : undefined,
            borderWidth: props.data.dealtype_code == null ? "1px" : undefined,
            borderStyle: props.data.dealtype_code == null ? "solid" : undefined,
          }}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          name="INVOICE DATE"
          type="Date"
          id="invoice_date"
          param="invoice_date"
          required="True"
          value={props.data.invoice_date}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEALER_CODE}
          type="Select"
          id="dealer_name"
          name="DEALER NAME"
          param="dealer_code"
          required="True"
          value={props.data.dealer_code}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DISTRIBUTOR_CODE}
          type="Select"
          id="customer_nm"
          name="CUSTOMER NAME"
          required="True"
          param="distributor_code"
          value={props.data.distributor_code}
          customers={props.editMode1 ? props.customers : customers}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEL_ADD}
          type="Select"
          id="del_add"
          name="DELIVERY ADD"
          param="del_add"
          required="True"
          value={props.data.del_add}
          addres={props.editMode1 ? props.addres : addres}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          type="Input"
          name="TRUCK NO"
          required="True"
          text_align="left"
          param="truck_number"
          value={props.data.truck_number}
          style={{
            // Apply the red border only if the field is null and the form has been submitted
            borderColor:
              props.isSubmitted && props.data.truck_number == null
                ? "red"
                : undefined,
            borderWidth:
              props.isSubmitted && props.data.truck_number == null
                ? "1px"
                : undefined,
            borderStyle:
              props.isSubmitted && props.data.truck_number == null
                ? "solid"
                : undefined,
          }}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChangegross}
          type="InputNumber"
          name="TARE WEIGHT"
          // text_align="right"
          param="tare_wt"
          required="True"
          value={props.data.tare_wt}
          style={{
            // Apply the red border only if the field is null and the form has been submitted
            borderColor:
              props.isSubmitted && props.data.tare_wt == null
                ? "red"
                : undefined,
            borderWidth:
              props.isSubmitted && props.data.tare_wt == null
                ? "1px"
                : undefined,
            borderStyle:
              props.isSubmitted && props.data.tare_wt == null
                ? "solid"
                : undefined,
          }}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChangegross}
          type="InputNumber"
          id="gross_weight"
          required="True"
          name="GROSS WEIGHT"
          // text_align="right"
          param="gross_wt"
          value={props.data.gross_wt}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          id="net_weight"
          type="Input"
          required="True"
          name="NET WEIGHT"
          // text_align="right"
          param="net_wt"
          value={props.data.net_wt}
          disabled={true}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          name="EWAY BILL DATE DATE"
          type="Date"
          required="True"
          param="eway_bill_date"
          value={props.data.eway_bill_date}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          type="Input"
          required="True"
          name="EWAY BILL NO"
          param="eway_bill"
          value={props.data.eway_bill}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          type="Input"
          name="SHIP TO"
          required="True"
          text_align="left"
          param="ship_to_cd"
          value={props.data.ship_to_cd}
        />

        <Col span={5} style={{ marginRight: "16px" }}>
          {/* Adjusted span for better width distribution */}
          <div
            style={{
              marginBottom: "8px",
              marginTop: "2px",
              // border: "2px solid red",
            }}
          >
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333", // Darker color, you can choose any color
              }}
            >
              NET QTY.
            </label>
            <input
              type="text"
              value={props.netQty}
              readOnly
              style={{
                width: "100%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>
        <Col span={5}>
          {/* Adjusted span for better width distribution */}
          <div style={{ marginBottom: "8px", marginTop: "2px" }}>
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333",
              }}
            >
              NET AMOUNT
            </label>
            <input
              type="text"
              value={props.netAmount}
              readOnly
              style={{
                width: "100%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                backgroundColor: "#ffffff",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>
        {props.data.invoice_type !== "w" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: 0 }}
          >
            Add item
          </Button>
        )}
      </Row>

      <ReqCodeModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRowClick={handleModalRowClick} // Function to handle when a row in the modal is clicked
        data={modalData} // Pass the data to be displayed in the modal
      />

      <CustomerModal
        title="API Data Modal"
        visible={isModalForApiVisible}
        onRowClick={handleApiModalRowClick}
        onClose={() => setIsModalForApiVisible(false)}
        // onCancel={handleApiModalClose}
        data1={codalData}
      />
    </div>
  );
};

export default BasicDetails;
