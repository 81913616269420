import { Row, Col, Skeleton, Tooltip, message } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import { Link } from "react-router-dom";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import { TreeDataState } from "@devexpress/dx-react-grid";
import { CssBaseline } from "@material-ui/core";

const TaxDetail = (props) => {
  console.log(props, "props in Tax detail");
  // console.log(props, "props in size detail");
  // // console.log(props, "props in size detail");
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [columnsup, setColumnsup] = useState([]);
  const [rowsup, setRowsup] = useState(null);
  const [drows, setDRows] = useState(null);
  const [taxdata, setTaxdata] = useState({});
  const identifiers = ["invoiceSize"];

  const HandleSaveTax = (event) => {
    setDRows(null);
    setRows(null);
    setColumns([]);
    props.setData((data) => {
      setDRows(null);
      setRows(null);
      setColumns([]);
      var chargedata = [];
      var netamt = [];
      var qty = 0;
      var amount = 0;
      let totalQty = 0;
      let totalAmount = 0;

      const postData = {
        ...data,
      };
      // Reset previous tax data
      setDRows(null);
      setRows(null);
      setColumns([]);
      props.setQty(0);
      props.setAmount(0);
      // Perform API request to calculate tax

      console.log(postData);
      // Mandatory fields to check

      // If validation fails, show message and stop the API call
      // for (const item of postData.invoiceSize) {
      //   // Check if item_code and hsn are not null
      //   if (item.item_code === null || item.hsn === null) {
      //     message.error({
      //       content: "Please Fill At Least One Row In The Item Detail Tab!!!",
      //       className: "custom-class",
      //       style: {
      //         marginTop: "1vh",
      //       },
      //     });
      //     return true;
      //   }
      // }

      // const hasNullFields = (invoiceSize) => {
      //   console.log("Checking fields...");
      //   return invoiceSize.some(
      //     (item) => item.item_code == null || item.hsn == null
      //   );
      // };

      console.log("postData:", postData);

      // const nullFieldsExist = hasNullFields(postData.invoiceSize);
      // console.log("Null fields exist:", nullFieldsExist);

      // if (nullFieldsExist) {
      //   console.log("Displaying error message.");
      //   message.error({
      //     content: "Please Fill At Least One Row In The Item Detail Tab!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   return; // Stop further execution
      // }

      axios
        .post(
          employeeData.URL + "/api/v1/salesInvoice/invoice-tax-cal-by-hsn/",
          postData,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log(response);
          const { newTax } = response.data.data;

          if (newTax.length === 0) {
            // Handle the case where newTax is empty
            message.info("No tax data available.");
            setRows([]); // Clear rows
            setColumns([]); // Clear columns
            return;
          }
          var ob = response.data.data.ob1;
          // var newTax = response.data.data.newTax;

          setDRows(null);
          setRows(null);
          setColumns([]);

          var amount = 0;
          var qty = 0;
          let l = 0;
          for (let i = 0; i < ob.length; i++) {
            l += ob[i].length;
            totalAmount += newTax[l - 1].RunningTotal;
            totalQty += Number(newTax[l - 1].qty);
          }
          props.setEditTode(true);
          // Set the qty and amount in the parent component using the passed setters
          props.setQty(totalQty);
          props.setAmount(totalAmount);
          // for (var i = 0; i < ob.length; i++) {
          //   l = l + ob[i].length;
          //   // // console.log(l);
          //   qty = qty + newTax[l - 1].qty;

          //   // // console.log(amount);
          // }

          // props.setQty(qty);
          // document.getElementById("net_amount").value = amount;

          chargedata.push(...response.data.data.newTax);

          setColumns((columns) => {
            let newCols = response.data.data.feild1[0].map((col) => {
              return {
                name: col.name,
                title: col.name.split("_").join(" ").toLowerCase(),
              };
            });

            const newNewCols = [
              { name: "SNO", title: "SNo" },
              { name: "charge_desc", title: "Charge Desc" },
              { name: "charge_value", title: "Charge Value" },
              { name: "charge_type", title: "Charge Type" },
              { name: "ref_chrg", title: "Ref Charge" },
              { name: "ref_on", title: "Ref On" },
              { name: "TaxValue", title: "TaxValue" },
              { name: "RunningTotal", title: "Running Total" },
            ];
            return newNewCols;
          });

          setRows((rows) => {
            let newRows = response.data.data.newTax.map((row, index) => {
              return {
                V: (
                  <Tooltip placement="bottom" title="View" color="#1777C4">
                    <Link
                      to={
                        "/sales/transaction/salesInvoice-form-view1/" +
                        row["charge_code"]
                      }
                      style={{ color: "#1777C4", fontWeight: "bolder" }}
                    >
                      <RiUserSearchFill
                        style={{
                          color: "#1777C4",
                          fontWeight: "bolder",
                          fontSize: "0.9rem",
                        }}
                      />
                    </Link>
                  </Tooltip>
                ),

                SNO: index + 1,
                ...row,
              };
            });

            return newRows;
          });
        });

      return {
        ...data,
        taxdata: taxdata,
        chargedata: chargedata,
        totQty: totalQty, // Return the updated qty
        totAmount: totalAmount, // Return the updated amount
      };
    });
  };

  return props.editTode ? (
    <>
      <Row className={classes["Row"]}>
        <Col md={14}>
          <p className={classes["Title"]}>Tax Details</p>
        </Col>
        {/* { // console.log(taxdata,"taxdata")} */}
        <Col className={classes["Col"]} md={10}></Col>
      </Row>
      <p></p>
      <Row className={classes["Row"]}>
        <Col lg={19} md={17}></Col>
        <Col className={classes["Col"]} lg={5} md={7}>
          <button
            className={classes["ProfileButton"]}
            onClick={(event) => HandleSaveTax(event)}
          >
            Tax Calculation
          </button>
        </Col>
      </Row>
      <p></p>
      {rows && columns.length > 0 ? (
        <>
          <EmployeeTable data={rows} columns={columns} val={true} />
        </>
      ) : (
        <>
          <Skeleton loading={false} />
          <Skeleton loading={false} />
          <Skeleton loading={false} />
          <Skeleton loading={false} />
        </>
      )}
    </>
  ) : (
    <>
      {
        //    props.data.map((data, index) => {

        // return (
        <div>
          <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
            <table>
              <tr>
                <th style={{ padding: "6px" }}>Charge Desc</th>
                <th style={{ whiteSpace: "nowrap" }}>Charge Value</th>
                <th style={{ padding: "6px" }}>Charge Type</th>
                <th>Ref Charge</th>
                <th>Ref On</th>
                <th>TaxValue</th>
                <th>Running Total</th>
              </tr>

              {props.data.map((row) => {
                return (
                  <tr>
                    <td>{row.charge_desc}</td>
                    <td>{row.charge_value}</td>
                    <td>{row.charge_type}</td>
                    <td>{row.ref_chrg}</td>
                    <td>{row.ref_on}</td>
                    <td>{row.taxvalue}</td>
                    <td>{row.runningtotal}</td>
                  </tr>
                );
              })}
            </table>
          </Row>

          <Row className={classes["Row"]} style={{ marginTop: "20px" }}>
            <Col lg={19} md={17}></Col>
            <Col
              className={classes["Col"]}
              lg={5}
              md={7}
              style={{ textAlign: "right" }}
            >
              <button
                className={classes["ProfileButton"]}
                onClick={(event) => HandleSaveTax(event)}
              >
                Tax Calculation
              </button>
            </Col>
          </Row>
        </div>

        // );

        //  })
      }
    </>
  );
};

export default TaxDetail;
