import { Row, Col, Card, Spin } from "antd"; // Import Spin for loading indicator
import classes from "./Dashboard.module.css";
import DataCards from "./DataCards";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import Charts from "./Charts";

const Dashboard = () => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  const employeeData = useContext(DataContext);
  // console.log(employeeData);
  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/dashboard/dash-board", {
        withCredentials: true,
      })
      .then((response) => {
        setAnalysis(response.data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // In case of error, also stop loading
      });
  }, []);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" /> {/* Loading spinner */}
      </Row>
    );
  }

  return (
    <>
      <Row gutter={16} className={classes["SiteLayoutBackground"]}>
        <Col xl={24} lg={24} md={24}>
          <DataCards data={analysis} />
          <Row>
            <Col
              lg={{ span: 24, offset: 0 }}
              md={24}
              xs={24}
              className={classes["ColC"]}
            >
              <Card className={classes["Card"]}>
                <Charts chartType="bar" data={analysis.data} />
              </Card>
            </Col>
          </Row>
        </Col>
        <p></p>
        <Col
          lg={{ span: 24, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts chartType="line" data1={analysis.itemQtyOfOrder} />
          </Card>
        </Col>
      </Row>
      <p></p>
    </>
  );
};

export default Dashboard;
