import React from "react";
import axios from "axios";
import Chat from "./Chat";
import AdminChat from "./AdminChat";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Row, Col } from "antd";

import classes from "./PayrollPageExtra.module.css";
// import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";

import SiderContentS from "../../components/SalesModule/SiderContentS/SiderContentS";

import { useEffect, useState } from "react";
import FieldDataS from "../../components/SalesModule/FieldDataS/FieldDataS";

import { DataProvider } from "../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;

const SalesModule = (props) => {
  const [profiledata, setProfiledata] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [miscList, setMiscList] = useState(null);
  const [userList, setUserList] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const employeeData = {
    URL: props.url,
    miscList: miscList,
    finyear1: props.finyear,
    // analysis: analysis,
  };
  // console.log(employeeData.analysis);
  useEffect(() => {
    axios
      .get(props.url + "/api/v1/salesMisc", { withCredentials: true })
      .then((response) => {
        // console.log(response);
        setMiscList((miscList) => {
          const newList = response.data.data.tables.rows;
          // console.log(newList);
          return newList;
        });
        // axios
        //   .get(props.url + "/api/v1/dashboard/dash-board", {
        //     withCredentials: true,
        //   })
        //   .then((response) => {
        //     // console.log(response);
        //     setAnalysis(response.data.data);
        //   });
      })

      .catch((err) => {
        // console.log(err.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get(props.url + "/api/v1/salesOrder/chat-bot-icon", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setUserList((miscList) => {
          const newList = response.data.data.site;
          // console.log(newList.spec_code, "ssssssssssssssssss");
          return newList;
        });
      })

      .catch((err) => {
        // console.log(err.response);
      });
  }, []);

  // console.log(props.finyear);

  // return (
  //   <Layout style={{ minHeight: "100vh" }}>
  //     <SiderContentS url={props.url} miscList={miscList} />

  //     <Layout>
  //       <Scrollbars>
  //         <DataProvider value={employeeData}>
  //           <Content>
  //             <FieldDataS />
  //           </Content>
  //         </DataProvider>
  //       </Scrollbars>
  //     </Layout>
  //   </Layout>
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "transparent" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: "transparent" }}
      >
        <SiderContentS url={props.url} miscList={miscList} />
      </Sider>

      <Layout className={classes["SiteLayout"]}>
        <Scrollbars>
          <DataProvider value={employeeData}>
            <Content>
              <FieldDataS />
            </Content>
          </DataProvider>
        </Scrollbars>
        <Row
          gutter={16}
          style={{
            backgroundColor: "transparent",
            width: "4rem",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            textAlign: "right",
          }}
        >
          <Col md={24}>
            {userList && userList.spec_code === "admin" ? <Chat /> : <Chat />}
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default SalesModule;
