import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;

const WeightBridgeView = (props) => {
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);
  const [backtrack, setBacktrack] = useState(null);
  const [viewinvoice, setViewInvoice] = useState(null);
  const [vad, setVAD] = useState(null);
  const [noedit, setNoEdit] = useState(false);

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);
    setVAD(null);

    axios
      .get(employeeData.URL + "/api/v1/weighbridge/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);

        setBacktrack(response.data.data.BevHeader[0].gate_no);

        setAllData((allData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });

        setStoreData((storeData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/weighbridge/view-additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setVAD((vad) => {
          let newad = response.data.data;
          return {
            ...newad,
            BEV_TYPE: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "I", VALUE: "Inward" },
                { KEY: "O", VALUE: "Outward" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [employeeData.URL, id]);

  useEffect(() => {
    setAD(null);
    setLoading(false);

    axios
      .get(employeeData.URL + "/api/v1/weighbridge/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            BEV_TYPE: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "I", VALUE: "Inward" },
                { KEY: "O", VALUE: "Outward" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    if (backtrack) {
      // console.log(backtrack, "thisd is wwwwwwwwwwwwwww");
      const fetchInvoiceData = async () => {
        try {
          const response = await axios.get(
            `http://sastetab.com/api/v1/weighbridge/invoice-data/${backtrack}`,
            { withCredentials: true }
          );

          // console.log(response, "view invoice");

          setViewInvoice(response.data.data);
        } catch (error) {
          // console.log(error);
        }
      };

      fetchInvoiceData();
    }
  }, [backtrack]);

  const handleSave = (event) => {
    const postData = {
      ...allData,
    };

    // console.log(postData);

    // console.log(allData);

    // console.log(JSON.stringify(postData, undefined, 2));

    axios
      .patch(
        employeeData.URL +
          "/api/v1/weighbridge/" +
          postData.BevHeader[0]["wt_code"],
        postData,
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        message.success({
          content: "Weight Bridge Updated Successfully!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
        history("/sales/transaction/weighbridge");
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/transaction/weighbridge");
  };

  return (
    <>
      {allData && vad && viewinvoice && ad ? (
        <Row className={classes["RowP"]}>
          <Col lg={1} md={1}></Col>
          <Col
            lg={editMode ? 13 : 13}
            md={editMode ? 13 : 13}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={8}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {loading ? (
                    <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                  ) : (
                    <>
                      <button
                        style={{
                          background:
                            "linear-gradient(179deg, rgba(13, 53, 148, 0.9) -102%, rgb(53, 211, 225) 162%)",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                        onClick={(event) => handleSave(event)}
                        className={classes["ProfileButton"]}
                      >
                        Save
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            "linear-gradient(179deg, rgba(13, 53, 148, 0.5) -102%, rgb(173, 216, 230) 162%)",
                          color: "#00008B",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                        onClick={(event) => handleCancel(event)}
                        className={classes["ProfileButton"]}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <Col md={8}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{
                    backgroundColor:
                      "linear-gradient(179deg, rgba(13, 53, 148, 0.5) -102%, rgb(173, 216, 230) 162%)",
                    color: "#00008B",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setEditMode(true);
                    setNoEdit(true);
                  }}
                  className={classes["ProfileButton"]}
                >
                  Edit Details
                </button>
                <button
                  style={{
                    background:
                      "linear-gradient(179deg, rgba(13, 53, 148, 0.5) -102%, rgb(173, 216, 230) 162%)",
                    color: "#00008B",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                  onClick={handleCancel}
                  className={classes["ProfileButton"]}
                >
                  Back
                </button>
              </div>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetail
                    create={true}
                    vad={vad}
                    setData={setAllData}
                    data={allData.BevHeader[0]}
                    editMode={editMode}
                    viewinvoice={viewinvoice}
                    ad={ad}
                    noedit={noedit}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default WeightBridgeView;
