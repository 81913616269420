import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";

const BasicDetail = (props) => {
  console.log(props, "prrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [cats, setCats] = useState([]);

  const employeeData = useContext(DataContext);

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["SaudatHeader"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        SaudatHeader: newdata,
      };
    });
  };

  const handleChange1 = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["SaudatHeader"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        SaudatHeader: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    // props.setData((data) => {
    //   const newdata = [...data["SaudatHeader"]];

    //   newdata[0][param] = val;
    //   return {
    //     ...data,
    //     SaudatHeader: newdata,
    //   };
    // });

    props.setData((data) => {
      // Create a new copy of salesOrder to avoid mutating the state directly
      const newdata = [...data["SaudatHeader"]];

      if (param === "dealer_code") {
        // console.log(data, "data when dealer selected");
        props.setEditMode1(false);
        // Clear previous values when a new dealer is selected
        newdata[0].cust_code = null; // Reset distributor code
        setCustomers(null); // Reset customers to null

        // Make API call to fetch customer list based on dealer code
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-customer/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            console.log(response);
            const customer = response.data.data.customer.rows; // Replace with the actual response structure
            setCustomers(customer);
          })
          .catch((error) => {
            console.error("Error fetching customer list:", error);
          });
      }

      if (param === "item_cat_code") {
        // Reset delivery site code when a new distributor code is selected
        props.setEditMode1(false);
        newdata[0].item_code = null; // Reset delivery site code
        setItems(null); // Reset address or other related fields

        // console.log(props);

        axios
          .get(
            employeeData.URL +
              "/api/v1/salesOrder/additional-data-of-customer/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const add = response.data.data.item.rows; // Replace with the actual response structure
            setItems(add); // Update state with the new address or details
          })
          .catch((error) => {
            console.error("Error fetching customer detail:", error);
          });
      }

      if (param === "item_code") {
        // Reset delivery site code when a new distributor code is selected
        props.setEditMode1(false);
        newdata[0].sauda_quality = null; // Reset delivery site code
        setCats(null); // Reset address or other related fields

        // // console.log(props);

        axios
          .get(
            employeeData.URL +
              "/api/v1/salesOrder/additional-data-of-item-cat/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log(response);
            const add = response.data.data.item.rows; // Replace with the actual response structure
            setCats(add); // Update state with the new address or details
          })
          .catch((error) => {
            console.error("Error fetching customer detail:", error);
          });
      }

      // Update the salesOrder object with the new parameter value
      newdata[0][param] = val;

      // Return the updated state
      return {
        ...data,
        SaudatHeader: newdata,
      };
    });
  };
  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["SaudatHeader"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        SaudatHeader: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="SAUDA CODE"
            param="sauda_code"
            value={props.data.sauda_code}
          />
        )}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACCOUNT NAME" param = "account_name" value = {props.data.account_name}/>    */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleDChange}
          name="CONTRACT DATE"
          type="Date"
          param="sauda_date"
          required="True"
          value={props.data.sauda_date}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEALER_CODE}
          type="Select"
          name="DEALER"
          param="dealer_code"
          required="True"
          value={props.data.dealer_code}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          type="Select"
          name="CUSTOMER"
          param="cust_code"
          value={props.data.cust_code}
          required="True"
          customers={props.editMode1 ? props.customers : customers}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ITEM_CAT_CODE}
          type="Select"
          name="ITEM CATEGORY"
          param="item_cat_code"
          required="True"
          value={props.data.item_cat_code}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          type="Select"
          name="ITEM"
          param="item_code"
          value={props.data.item_code}
          required="True"
          items={props.editMode1 ? props.items : items}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          type="Select"
          name="GRADE"
          param="sauda_quality"
          value={props.data.sauda_quality}
          required="True"
          cats={props.editMode1 ? props.cats : cats}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="CONTRACT QTY"
          param="sauda_qty"
          type="Input"
          required="True"
          value={props.data.sauda_qty}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="CONTRACT RATE"
          param="sauda_rate"
          type="Input"
          required="True"
          value={props.data.sauda_rate}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="CONTRACT REMARK"
          type="Input"
          param="sauda_remark"
          value={props.data.sauda_remark}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.FREIGHT_TYPE_CODE}
          type="Select"
          name="FREIGHT TYPE"
          param="freight_type_code"
          required="True"
          value={props.data.freight_type_code}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="ACTUAL QTY"
          param="actual_qty"
          type="Input"
          required="True"
          value={props.data.actual_qty}
        />
      </Row>
    </div>
  );
};

export default BasicDetail;
