// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Form,
//   Input,
//   Button,
//   Select,
//   DatePicker,
//   message,
//   Table,
//   Row,
//   Col,
//   Card,
// } from "antd";
// import dayjs from "dayjs";
// import axios from "axios";
// import classes from "../Pages.module.css";

// const { Option } = Select;

// const OutwardGatePass = () => {
//   const [formListData, setFormListData] = useState(null);
//   const [outwardCode, setOutwardCode] = useState("");
//   const [gpDate, setGpDate] = useState("");
//   const [truckNo, setTruckNo] = useState("");
//   const [totalWeight, setTotalWeight] = useState("");
//   const [tareWeight, setTareWeight] = useState("");
//   const [netWeight, setNetWeight] = useState("");
//   const [partyWeight, setPartyWeight] = useState("");
//   const [pendingInward, setPendingInward] = useState("");
//   const [driverName, setDriverName] = useState("");
//   const [gateSlipNo, setGateSlipNo] = useState("");
//   const [invoiceCode, setInvoiceCode] = useState("");
//   const [challanDate, setChallanDate] = useState("");
//   const [challanNo, setChallanNo] = useState("");
//   const [distributorCode, setDistributorCode] = useState("");
//   const [tableData, setTableData] = useState([]);
//   const [finalData, setFinalData] = useState(null);
//   const [pendingInwardOptions, setPendingInwardOptions] = useState([]);
//   const [invoiceCodeOptions, setInvoiceCodeOptions] = useState([]);
//   const [filteredInvoiceOptions, setFilteredInvoiceOptions] = useState([]);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchDropdownOptions = async () => {
//       try {
//         const response = await axios.get(
//           "http://sastetab.com/api/v1/outwardgatepassdata/gpdataout",
//           {
//             withCredentials: true,
//           }
//         );
//         console.log(response);
//         setPendingInwardOptions(response.data.data.pendingInward || []);
//         setInvoiceCodeOptions(response.data.data.invoiceCode || []);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         message.error("Failed to fetch dropdown options");
//       }
//     };

//     fetchDropdownOptions();
//   }, []);

//   useEffect(() => {
//     const fetchTableData = async () => {
//       if (invoiceCode) {
//         try {
//           const response = await axios.get(
//             `http://sastetab.com/api/v1/outwardgatepassdata/gptabledata/${invoiceCode}`,
//             {
//               withCredentials: true,
//             }
//           );
//           setTableData(response.data.data || []);
//         } catch (error) {
//           console.error("Error fetching table data:", error);
//           message.error("Failed to fetch table data");
//         }
//       }
//     };

//     fetchTableData();
//   }, [invoiceCode]);

//   useEffect(() => {
//     setFilteredInvoiceOptions(
//       invoiceCodeOptions.filter((option) => option.truck_no === truckNo)
//     );
//   }, [truckNo, invoiceCodeOptions]);

//   useEffect(() => {
//     const selectedInvoice = invoiceCodeOptions.find(
//       (item) => item.invoice_no === invoiceCode
//     );
//     console.log(selectedInvoice);
//     if (selectedInvoice) {
//       setDistributorCode(selectedInvoice.distributor_code || "");
//     } else {
//       setDistributorCode("");
//     }
//   }, [invoiceCode, invoiceCodeOptions]);

//   const createFinalData = () => ({
//     outwardCode,
//     gpDate,
//     truckNo,
//     totalWeight,
//     tareWeight,
//     netWeight,
//     partyWeight,
//     pendingInward,
//     driverName,
//     gateSlipNo,
//     invoiceCode,
//     challanDate,
//     challanNo,
//     distributorCode,
//     tableData,
//   });

//   useEffect(() => {
//     setFinalData(createFinalData());
//   }, [
//     outwardCode,
//     gpDate,
//     truckNo,
//     totalWeight,
//     tareWeight,
//     netWeight,
//     partyWeight,
//     pendingInward,
//     driverName,
//     gateSlipNo,
//     invoiceCode,
//     challanDate,
//     challanNo,
//     distributorCode,
//     tableData,
//   ]);

//   const handleSave = async () => {
//     try {
//       await axios.post(
//         "http://sastetab.com/api/v1/outwardgatepassdata/savedogdata",
//         finalData,
//         {
//           withCredentials: true,
//         }
//       );
//       message.success("Gate pass data saved successfully");
//       navigate("/view/gatePass/outward");
//     } catch (error) {
//       message.error("Failed to save gate pass data");
//       console.error("Error saving data:", error);
//     }
//   };

//   const handlePendingInwardChange = (value) => {
//     setPendingInward(value);
//     const selectedInward = pendingInwardOptions.find(
//       (item) => item.outward_code === value
//     );
//     if (selectedInward) {
//       setTruckNo(selectedInward.truck_no || "");
//       setDriverName(selectedInward.driver_name || "");
//     } else {
//       setTruckNo("");
//       setDriverName("");
//     }
//   };

//   const colNames = [
//     "Store Name",
//     "Item Name",
//     "Quality Name",
//     "Size Name",
//     "Quantity",
//   ];

//   return (
//     <div className="outward-gate-pass">
//       <Card
//         title={
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <span>Outward Gate Pass</span>
//             <Button
//               type="primary"
//               onClick={handleSave}
//               className={classes.saveButton}
//             >
//               Save
//             </Button>
//           </div>
//         }
//         bordered={true}
//         style={{ marginBottom: 8, width: "90%", padding: "10px" }}
//       >
//         <Form layout="vertical">
//           <Row gutter={8}>
//             <Col span={4}>
//               <Form.Item label="Outward Code">
//                 <Input
//                   value={outwardCode}
//                   onChange={(e) => setOutwardCode(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Outward Date">
//                 <DatePicker
//                   format="DD-MM-YYYY"
//                   value={gpDate ? dayjs(gpDate, "YYYY-MM-DD") : null}
//                   onChange={(date) =>
//                     setGpDate(date ? date.format("YYYY-MM-DD") : "")
//                   }
//                   style={{ width: "100%" }}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Truck Number">
//                 <Input
//                   value={truckNo}
//                   onChange={(e) => setTruckNo(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Total Weight">
//                 <Input
//                   type="number"
//                   value={totalWeight}
//                   onChange={(e) => setTotalWeight(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Tare Weight">
//                 <Input
//                   type="number"
//                   value={tareWeight}
//                   onChange={(e) => setTareWeight(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Net Weight">
//                 <Input
//                   type="number"
//                   value={netWeight}
//                   onChange={(e) => setNetWeight(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Party Weight">
//                 <Input
//                   type="number"
//                   value={partyWeight}
//                   onChange={(e) => setPartyWeight(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Pending Inward">
//                 <Select
//                   value={pendingInward}
//                   onChange={handlePendingInwardChange}
//                 >
//                   {pendingInwardOptions.map((option) => (
//                     <Option
//                       key={option.outward_code}
//                       value={option.outward_code}
//                     >
//                       {option.outward_code}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Driver Name">
//                 <Input
//                   value={driverName}
//                   onChange={(e) => setDriverName(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Gate Slip Number">
//                 <Input
//                   value={gateSlipNo}
//                   onChange={(e) => setGateSlipNo(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Invoice Code">
//                 <Select
//                   value={invoiceCode}
//                   onChange={(value) => setInvoiceCode(value)}
//                 >
//                   {filteredInvoiceOptions.map((option) => (
//                     <Option key={option.invoice_no} value={option.invoice_no}>
//                       {option.invoice_no}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Challan Date">
//                 <DatePicker
//                   format="DD-MM-YYYY"
//                   value={challanDate ? dayjs(challanDate, "YYYY-MM-DD") : null}
//                   onChange={(date) =>
//                     setChallanDate(date ? date.format("YYYY-MM-DD") : "")
//                   }
//                   style={{ width: "100%" }}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Challan Number">
//                 <Input
//                   value={challanNo}
//                   onChange={(e) => setChallanNo(e.target.value)}
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={4}>
//               <Form.Item label="Distributor Code">
//                 <Input value={distributorCode} disabled />
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//         <Table
//           columns={colNames.map((col) => ({
//             title: col,
//             dataIndex: col.toLowerCase().replace(/\s+/g, "_"),
//             key: col,
//           }))}
//           dataSource={tableData}
//           pagination={false}
//         />
//         <Button
//           type="primary"
//           onClick={handleSave}
//           className={classes.button}
//           style={{ marginTop: "16px" }}
//         >
//           Save
//         </Button>
//       </Card>
//     </div>
//   );
// };

// export default OutwardGatePass;

import React, { useState, useEffect } from "react";
import { Form, Input, Select, DatePicker, Button, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
//import "./OutwardGatePass.css";
import dayjs from "dayjs"; // Importing dayjs
import axios from "axios";
const { Option } = Select;

function OutwardGatePass() {
  const [invoiceCode, setInvoiceCode] = useState("");
  const formFields = [
    { label: "Outward Code", name: "gpCode" },
    { label: "Outward Date", name: "gpDate", type: "date" },
    { label: "Truck Number", name: "truckNo" },
    { label: "Total Weight", name: "totalWeight", type: "number" },
    { label: "Tare Weight", name: "tareWeight", type: "number" },
    { label: "Net Weight", name: "netWeight", type: "number" },
    { label: "Party Weight", name: "partyWeight", type: "number" },
    { label: "Pending Inward", name: "pendingInward" },
    { label: "Driver Name", name: "driverName" },
    { label: "Gate Slip No.", name: "gateSlipNo" },
    { label: "Invoice Code", name: "invoiceCode" },
    { label: "Challan Date", name: "challanDate", type: "date" },
    { label: "Challan Number", name: "challanNo" },
    { label: "Customer Name", name: "customerName" },
  ];

  const [formListData, setFormListData] = useState(null);
  const [formData, setFormData] = useState({});
  const [finalData, setFinalData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Fetch initial data for the form (like dropdown options)
  // useEffect(() => {
  //   axios
  //     .get("http://sastetab.com/api/v1/outwardgatepassdata/gpdataout", {
  //       withCredentials: true,
  //     })
  //     .then((res) => res.json())
  //     .then((data) => setFormListData(data))
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/outwardgatepassdata/gpdataout",
          {
            withCredentials: true,
          }
        );
        console.log(response);

        setFormListData(response.data.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch dropdown options");
      }
    };

    fetchDropdownOptions();
  }, []);

  // Handle form input changes
  const handleInputChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const handleInvoiceCodeChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      invoiceCode: value,
    }));
  };

  // const fetchInvoiceData = (invoiceCode) => {
  //     const apiUrl = `http://sastetab.com/api/outward/gatepass/invoice/data/${invoiceCode}`;
  //     fetch(apiUrl)
  //       .then((response) => response.json())
  //       .then((data) => setTableData(data.innertableData || []))
  //       .catch((error) => console.error("Error fetching data:", error));

  // };

  useEffect(() => {
    const fetchTableData = async () => {
      if (formData.invoiceCode) {
        try {
          const response = await axios.get(
            `http://sastetab.com/api/v1/outwardgatepassdata/gptabledata/${formData.invoiceCode}`,
            {
              withCredentials: true,
            }
          );
          console.log(response);
          setTableData(response.data.data || []);
        } catch (error) {
          console.error("Error fetching table data:", error);
          message.error("Failed to fetch table data");
        }
      }
    };

    fetchTableData();
  }, [formData.invoiceCode]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const body = { ...formData, tableData };
      const response = await fetch(
        "http://sastetab.com/api/gatepassdata/outward/handleSave",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      console.log("Data saved successfully:", data);
      navigate("/view/gatePass/outward");
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Table columns for the invoice data
  const columns = [
    { title: "Store Name", dataIndex: "store_cd", key: "store_cd" },
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Quality Name", dataIndex: "grade", key: "grade" },
    { title: "Quantity", dataIndex: "qty", key: "qty" },
  ];

  return (
    <div className="outward-gate-pass">
      <h1>Outward Gate Pass</h1>
      <Form
        layout="vertical"
        onValuesChange={handleInputChange}
        initialValues={formData}
        autoComplete="off"
      >
        <div className="form-columns">
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={[
                { required: true, message: `Please input ${field.label}` },
              ]}
            >
              {field.type === "date" ? (
                <DatePicker
                  format="YYYY-MM-DD"
                  value={
                    formData[field.name] ? dayjs(formData[field.name]) : null
                  }
                  onChange={(date) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      [field.name]: date ? date.format("YYYY-MM-DD") : "",
                    }))
                  }
                />
              ) : field.name === "invoiceCode" ? (
                <Select
                  onChange={handleInvoiceCodeChange}
                  value={formData.invoiceCode || ""}
                >
                  {formListData?.invoiceCode?.map((option) => (
                    <Option key={option.invoice_no} value={option.invoice_no}>
                      {option.invoice_no}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input
                  type={field.type || "text"}
                  value={formData[field.name] || ""}
                />
              )}
            </Form.Item>
          ))}
        </div>
      </Form>

      <Table
        dataSource={tableData.innertableData}
        columns={columns}
        rowKey={(record) => record.id || record.item}
        pagination={false}
      />

      <div className="action-buttons">
        <Button type="primary" onClick={handleSave} loading={loading}>
          Save
        </Button>
        <Button
          type="default"
          onClick={() => navigate("/view/gatePass/outward")}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default OutwardGatePass;
