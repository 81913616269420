import { Row, Col, Skeleton, Tooltip } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai"; // Import AiOutlineEdit icon
import { Link } from "react-router-dom";

const Breakdown_details = (props) => {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(employeeData.URL + "/api/v1/breakdown/show/breakdown", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        const newCols = response.data.data.BREAKDOWN.fields.map((col) => ({
          name: col.name,
          title: col.name.split("_").join(" ").toLowerCase(),
        }));

        const updatedColumns = [
          { name: "D", title: "D" },

          { name: "SNO", title: "SNo" },
          ...newCols,
        ];

        setColumns(updatedColumns);

        const updatedRows = response.data.data.BREAKDOWN.rows.map(
          (row, index) => ({
            D: (
              <Tooltip placement="bottom" title="Delete" color="red">
                <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                  <AiFillDelete
                    onClick={(event) =>
                      deleteHandler(
                        event,
                        index,
                        response.data.data.BREAKDOWN.rows,
                        row["code"]
                      )
                    }
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                      fontSize: "0.8rem",
                    }}
                  />
                </Link>
              </Tooltip>
            ),

            SNO: index + 1,
            ...row,
          })
        );

        setRows(updatedRows);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const deleteHandler = (event, index, newrows, c) => {
    // console.log(index);
    // console.log(index);

    axios
      .delete(
        employeeData.URL + "/api/v1/breakdown/show/breakdown/" + c.toString(),
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const rowvals = newrows.filter((row) => row["code"] !== c);
        // console.log(rowvals);
        setRows((rows) => {
          let newDRows = rowvals.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, rowvals, row["code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <Row className={classes["Row"]}>
        <Col md={14}>
          <p className={classes["Title"]}>Breakdown Details</p>
        </Col>
        <Col className={classes["Col"]} md={10}></Col>
      </Row>
      <p></p>
      <Row className={classes["Row"]}>
        <Col lg={19} md={17}></Col>
        <Col className={classes["Col"]} lg={5} md={7}>
          <Link to="/production/transaction/breakdown-create">
            <button
              className={classes["ProfileButton"]}
              // style={{ fontSize: "20px", color: "#ffffff", width: "100px" }}
            >
              {/* <AiOutlineEdit
                style={{ marginRight: "5px", fontSize: "1.2rem" }}
              /> */}
              <span
                style={{ verticalAlign: "middle", display: "inline-block" }}
              >
                Add
              </span>
            </button>
          </Link>
        </Col>
      </Row>
      <p></p>
      {rows && columns.length > 0 ? (
        <EmployeeTable data={rows} columns={columns} val={true} />
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default Breakdown_details;
