import React from "react";
import { Modal, Table } from "antd";

const ReqCodeModal = ({ visible, onClose, onRowClick, data }) => {
  const columns = [
    { title: "Sauda Code", dataIndex: "sauda_code", key: "sauda_code" },
    {
      title: "Customer Name",
      dataIndex: "distributor_desc",
      key: "distributor_desc",
    },
    { title: "Dealer Name", dataIndex: "dealer_desc", key: "dealer_desc" },
  ];

  return (
    <Modal
      title="Select Sauda Code"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
    >
      <Table
        dataSource={data}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
              onClose();
            },
          };
        }}
      />
    </Modal>
  );
};

export default ReqCodeModal;
