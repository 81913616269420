import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message, Button, Table } from "antd";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";

import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext, useRef } from "react";
import BasicDetail from "./BasicDetailPR";
import SizeDetailPR from "./SizeDetailPR";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const identifiers = ["purreqDetail"];

const RequisitionNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();
  const [mrirdata, setmrirdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(false);
  const [errors, setErrors] = useState({});
  const sizeRef = useRef(null);

  const [allData, setAllData] = useState({
    poHdr: [
      {
        deal_date: dayjs(new Date()).format("DD-MM-YYYY"),
        mrir_code: null,
        truck_number: null,
        trans_code: null,
        remarks: null,
        party_code: null,
        mrir_date: dayjs(new Date()).format("DD-MM-YYYY"),
        bill_date: dayjs(new Date()).format("DD-MM-YYYY"),
        bill_no: null,
        preturn_date: dayjs(new Date()).format("DD-MM-YYYY"),
        deal_type_cd: null,
        preturn_code: null,
      },
    ],
    purreqDetail: [
      {
        item_code: null,
        uom_code: null,
        size_code: null,
        quality_code: null,
        mrir_wt: null,
        return_wt: null,
        mrir_pcs: null,
        return_pcs: null,
        total_qty: null,
        remarks: null,
        rate: null,
        amount: null,
      },
    ],
  });

  const validateFields = () => {
    const errors = {};
    const requiredFields = [
      "deal_date",
      "mrir_code",
      "remarks",
      "party_code",
      "mrir_date",
      "bill_date",
      "preturn_date",
      "deal_type_cd",
    ];

    requiredFields.forEach((field) => {
      if (!allData.poHdr[0][field]) {
        errors[field] = `Field ${field} is required`;
      }
    });

    allData.purreqDetail.forEach((row, index) => {
      Object.keys(row).forEach((field) => {
        if (!row[field]) {
          if (!errors.purreqDetail) errors.purreqDetail = {};
          if (!errors.purreqDetail[index]) errors.purreqDetail[index] = {};
          errors.purreqDetail[index][field] = `Field ${field} in row ${
            index + 1
          } is required`;
        }
      });
    });

    return errors;
  };

  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(`${employeeData.URL}/api/v1/purchaseReturnRoute/additional-data`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [employeeData.URL]);

  const handleRowClick = async (record) => {
    // console.log("Clicked Row Data:", record);

    try {
      const response = await axios.get(
        `http://sastetab.com/api/v1/purchasereturn/Purchasereturnnew`,
        {
          params: {
            mrir_code: record.mrir_code,
          },
        }
      );

      const responseData = response.data;

      props.setData((data) => ({
        ...data,
        poHdr: [
          {
            ...data.poHdr[0],
            mrir_code: record.mrir_code,
            additionalData: responseData.additionalField,
          },
        ],
      }));

      //setIsModalVisible(false); // Close the modal after selection
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const validateFields = () => {
  //   const requiredFields = [
  //     'mrir_code', 'truck_no', 'remarks', 'party_code',
  //     'mrir_date', 'bill_date', 'preturn_date', 'deal_type_cd'
  //   ];
  //   const newErrors = {};
  //   requiredFields.forEach(field => {
  //     if (!props.data[field]) {
  //       newErrors[field] = 'This field is required';
  //     }
  //   });
  //   return newErrors;
  // };

  // Define sample data for the table (replace with your actual data)

  const handleSave = async () => {
    // const validationErrors = validateFields();
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   message.error("Please fill all required fields!");
    //   return;
    // }

    // Prepare post data
    const postData = { ...allData };
    // console.log(postData, "8765432qweru7iu6t4r3dwdfu6");

    setLoading(true);

    try {
      const response = await axios.post(
        `${employeeData.URL}/api/v1/purchaseReturnRoute/create-purchaserrt`,
        postData,
        { withCredentials: true }
      );

      if (response.data.status === "fail") {
        message.error({
          content: response.data.message,
          className: "custom-class",
          style: { marginTop: "2vh" },
        });
      } else {
        message.success({
          content: "Requisition Successfully Saved!!!",
          className: "custom-class",
          style: { marginTop: "2vh" },
        });
        history("/purchase/transaction/PurchaseReturn/Purchasereturnview");
      }
    } catch (error) {
      message.error({
        content: "An Error Occurred!!!!",
        className: "custom-class",
        style: { marginTop: "2vh" },
      });
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setLoading(false);
    history("/purchase/transaction/PurchaseReturn/Purchasereturnview");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          />
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#071e54",
                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff",
                      height: "40px",
                    }}
                    onClick={handleSave}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f";
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff";
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#071e54",
                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff",
                      height: "40px",
                    }}
                    onClick={handleCancel}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f";
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff";
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1} />
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Col style={{ marginTop: "5px", marginBottom: "-28px" }}>
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Purchase Return
                </span>
                <BasicDetail
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.poHdr[0]}
                  editMode={editMode}
                  mrirdata={mrirdata}
                  setmrirdata={setmrirdata}
                />
              </Col>
              {/* <Tabs
                defaultActiveKey="0"
                centered
                type="line"
              >
                <TabPane
                  tab={<span style={{ fontWeight: "bold", color: "#000" }}>Item Details</span>}
                  key="0"
                  style={{ marginTop: "-16px" }}
                >
                  <div style={{ overflowX: "scroll" }}>
                    <SizeDetailPR
                      ad={ad}
                      setData={setAllData}
                      data={allData.purreqDetail}
                      editMode={editMode}
                      editDode={editDode}
                      setEditDode={setEditDode}
                      sizeRef={sizeRef}
                    />
                  </div>
                </TabPane>
              </Tabs> */}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </>
  );
};
export default RequisitionNew;
