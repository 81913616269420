import React from "react";
import { Table, InputNumber, Select } from "antd";
const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
}) => {
  // Define columns
  const columns = [
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    // { title: "Quality Code", dataIndex: "quality_code", key: "quality_code" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Uniq Id", dataIndex: "unique_code", key: "unique_code" },
    {
      title: "Rate",
      key: "item_rate",
      dataIndex: "item_rate",
    },

    {
      title: "Dis On",
      key: "dison",
      render: (text, record) => (
        <Select
          value={record.disOn}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "disOn")
          }
          style={{ width: 120 }}
        >
          <Option value="r">Rate</Option>
          <Option value="ta">Total Amt</Option>
        </Select>
      ),
    },

    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter discount"
          value={record.discount}
          onChange={(value) => onDiscountChange(value, record.unique_code)}
          min={0}
        />
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{record.quantity * (record.item_rate || 0)}</span>
      ),
    },

    {
      title: "Dis Type",
      key: "distype",
      render: (text, record) => (
        <Select
          value={record.distype}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "distype")
          }
          style={{ width: 120 }}
        >
          <Option value="p">%</Option>
          <Option value="a">A</Option>
        </Select>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "finalRate",
      key: "finalRate",
    },

    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_code} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
