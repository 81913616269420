import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { TabPane } = Tabs;
const identifiers = ["BevHeader"];

const requiredFields = [
  { param: "wb_type", name: "WEIGHT BRIDGE TYPE" },
  { param: "truck_no", name: "VEHICLE NUMBER" },
  { param: "gate_no", name: "GATE PASS NUMBER" },
  { param: "in_time", name: "IN TIME" },
  { param: "in_wt", name: "IN WEIGHT" },
  { param: "dt", name: "DATE" },
];

const RecordNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  //const [flag, setFlag] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [allData, setAllData] = useState({
    BevHeader: [
      {
        wt_code: null,
        dt: dayjs(new Date()).format("DD-MM-YYYY"),
        wb_type: "I",
        truck_no: null,
        cust_party_type: null,
        cust_party_code: null,
        gate_no: null,
        in_time: dayjs(new Date()).format("HH:mm:ss"),
        out_time: null,
        in_wt: null,
        out_wt: null,
        rmk: null,
        net_wt: null,
        acid_wt: null,
        party_display_name: null,
        customer_display_name: null,
        invoice_code: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    setInvoiceData(null);

    axios
      .get(employeeData.URL + "/api/v1/weighbridge/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,

            BEV_TYPE: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "I", VALUE: "Inward" },
                { KEY: "O", VALUE: "Outward" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    // console.log("ytu467676");
    var formv = true;
    let emptyFields = [];

    identifiers.forEach((val) => {
      // console.log(val);
      // console.log(val);
      let count = 0;
      allData[val].forEach((data) => {
        requiredFields.forEach((field) => {
          if (!data[field.param]) {
            formv = false;
            emptyFields.push(field.name);
          }
        });
      });
    });

    if (!formv) {
      message.error({
        content: `Fill Empty Fields: ${emptyFields.join(", ")}`,
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      return;
    }

    if (formv) {
      setLoading(true);
      // setFlag(true);
      const postData = {
        ...allData,
      };
      // console.log(postData);

      axios
        .post(
          employeeData.URL + "/api/v1/weighbridge/create-inward",
          postData,
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          message.success({
            content: "New Record Created Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/gate/transaction/weighbridge");
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/gate/transaction/weighbridge");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    // style={{ backgroundColor: "#D0F0C0", color: "#234F1E" }}
                    style={{
                      background:
                        "linear-gradient(179deg, rgba(13, 53, 148, 0.9) -102%, rgb(53, 211, 225) 162%)",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButton"]}
                  >
                    <strong>Save</strong>
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    // style={{ backgroundColor: "#FC9483", color: "#640000" }}
                    style={{
                      backgroundColor:
                        "linear-gradient(179deg, rgba(13, 53, 148, 0.5) -102%, rgb(173, 216, 230) 162%)",
                      color: "#00008B",
                      fontWeight: "bold",
                    }}
                    onClick={(event) => handleCancel(event)}
                    className={classes["ProfileButton"]}
                  >
                    <strong>Cancel</strong>
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                style={{
                  backgroundColor:
                    "linear-gradient(179deg, rgba(13, 53, 148, 0.5) -102%, rgb(173, 216, 230) 162%)",
                  color: "#00008B",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetail
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.BevHeader[0]}
                    editMode={editMode}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RecordNew;
